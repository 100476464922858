/* eslint-disable max-len */
import React from 'react';

const BackArrow: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 15 14"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M14.1666 6.16658H4.02492L8.68325 1.50825L7.49992 0.333252L0.833252 6.99992L7.49992 13.6666L8.67492 12.4916L4.02492 7.83325H14.1666V6.16658Z"/>
  </svg>
);

export default BackArrow;
