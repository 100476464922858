import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useMemo, useEffect } from 'react';

import { formatSectionsMetadata } from '../../utils/apiUtils';
import useEnsisQuery from '../../hooks/useEnsisQuery';
import { CenteredLoader } from '../../components';
import { PROPOSAL_STATUS_TYPES_PRE_AUTHORING, type ProposalStatusType } from '../../utils/constants';

const EditorPage: React.FC = () => {
  const { proposalUid, section, organization } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { data, isLoading, isSuccess } = useEnsisQuery(`/app/proposals/${proposalUid}/data`);

  const setupComplete = !PROPOSAL_STATUS_TYPES_PRE_AUTHORING.includes(data?.status as ProposalStatusType);
  const sectionData = useMemo(
    () => (formatSectionsMetadata(data?.sections) ?? []),
    [data, section]
  );

  useEffect(() => {
    if (setupComplete && isSuccess && (pathname === `/org/${organization}/proposals/${proposalUid}` ||
       pathname === `/org/${organization}/proposals/${proposalUid}/`)) {
      navigate(`edit/${sectionData[0].uid}`, { replace: true });
    };
  }, [location.pathname, isSuccess, setupComplete]);

  if (isLoading) {
    return <CenteredLoader style={{ height: '100vh', width: '100%' }} />;
  }

  return (
    <Outlet />
  );
};

export default EditorPage;
