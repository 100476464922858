import { Box, Button, Group, ScrollArea, Text, Stack, Modal, Divider, ActionIcon } from '@mantine/core';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useParams } from 'react-router-dom';

import { useDisclosure } from '@mantine/hooks';

import {
  BulkRequirementButtonGroup,
  CenteredLoader,
  OpportunityDocumentsViewer,
  RequirementRow
} from '../../components';

import useEnsisQuery from '../../hooks/useEnsisQuery';
import {
  type RequirementType,
  formatRequirementData,
  sortOpportunityFilesByRequirementCount,
  DEFAULT_EMPTY_REQUIREMENT
} from '../../utils/requirementUtils';

import {
  type WithoutPermissions,
  type OpportunityFile,
  type OpportunityRequirement,
  type Proposal,
  type RequirementResponse
} from '../../types/apiTypes';

import { AddRequirement, AutosortRequirements, EditRequirement } from '../../components/Modals';

import { defaultModalProps } from '../../utils/mantineUtils';

import { ArrowRight, SplitView, Star } from '../../icons';
import classes from './SetupProposalClasses.module.css';
import { type SectionData } from '../Editor/SectionEditor';
import DeleteRequirements from '../../components/Modals/DeleteRequirements';
import { FileRequirementsViewDropdown } from '../../components/DropdownMenus';
import { type RequirementsRowRef } from '../../components/RequirementRow/RequirementRow';

interface Props {
  opportunityUid: string
  proposal: Proposal
  sections: SectionData[]
}
/* eslint-disable-next-line max-len */
const EMPTY_STATE_TEXT = 'No unsorted requirements. Highlight text in the document or use the menu to add requirements associated with this file.';

const RequirementsListByFile: React.FC<Props> = (props: Props) => {
  const { proposal, sections, opportunityUid } = props;
  const { proposalUid } = useParams();

  const [currentFileIndex, setCurrentFileIndex] = useState(0);
  const [showDocuments, setShowDocuments] = useState(true);
  const { data: opportunityFiles, isLoading: filesLoading } = useEnsisQuery(
    '/app/opportunity-files',
    {
      queryParams: { opportunity_uid: opportunityUid, generate_download_url: true }
    }
  );

  const { data: requirementResponseData, isLoading: requirementResponseLoading } = useEnsisQuery(
    '/app/requirement-responses',
    {
      queryParams: { proposal_uid: proposalUid }
    }
  );

  const requirementRowRefs = useRef<Record<string, RequirementsRowRef | null>>({});
  const isLoading = filesLoading || requirementResponseLoading;

  const [checkedRequirements, setCheckedRequirements] = useState<RequirementType[]>([]);
  const handleCheckRequirement = useCallback((req: RequirementType) => {
    if (checkedRequirements.some((_req) => _req.requirementUid === req.requirementUid)) {
      setCheckedRequirements(checkedRequirements.filter((_req) => _req.requirementUid !== req.requirementUid));
    } else {
      setCheckedRequirements([...checkedRequirements, req]);
    }
  }, [checkedRequirements]);

  const sortedOpportunityFiles = useMemo(() => {
    const _requirements: Array<WithoutPermissions<OpportunityRequirement>> = requirementResponseData?.items?.map(
      (req) => req.requirement as OpportunityRequirement
    ) ?? [];
    return sortOpportunityFilesByRequirementCount(
      opportunityFiles?.items ?? [],
      _requirements
    );
  }, [opportunityFiles?.items]);
  const inBulkEditMode = checkedRequirements.length > 0;
  const [sortRequirementOpened, sortRequirementHandlers] = useDisclosure();
  const [editRequirementOpened, editRequirementHandlers] = useDisclosure();
  const [deleteRequirementsOpened, deleteRequirementsHandlers] = useDisclosure();
  const [addRequirementOpened, addRequirementHandlers] = useDisclosure();

  const unsortedRequirements = useMemo(() => requirementResponseData?.items?.filter(
    (req) => req.proposal_section === null
  ) ?? [] as RequirementResponse[], [requirementResponseData?.items]);

  const [focusedRequirementUid, setFocusedRequirementUid] = useState<string>('');
  const [openFileUids, setOpenFileUids] = useState<string[]>(
    sortedOpportunityFiles?.map((file) => file.uid ?? '') ?? []
  );

  useEffect(() => {
    setOpenFileUids(sortedOpportunityFiles?.map((file) => file.uid ?? '') ?? []);
  }, [sortedOpportunityFiles]);

  const [requirementToEdit, setRequirementToEdit] = useState<RequirementType>(DEFAULT_EMPTY_REQUIREMENT);
  const toggleFocusedRequirement = useCallback((requirementUid: string) => {
    if (requirementUid === focusedRequirementUid) {
      setFocusedRequirementUid('');
    } else {
      setFocusedRequirementUid(requirementUid);
    }
  }, [focusedRequirementUid]);

  const onOpenEditRequirement = useCallback((req: RequirementType) => {
    setRequirementToEdit(req);
    editRequirementHandlers.open();
  }, []);

  useEffect(() => {
    if (focusedRequirementUid !== '') {
      requirementRowRefs.current[focusedRequirementUid]?.scrollIntoView();
    }
  }, [unsortedRequirements.length, focusedRequirementUid]);

  const getRequirement = (req: RequirementType) => {
    return (
      <RequirementRow
        ref={(el) => { requirementRowRefs.current[req.requirementUid] = el; }}
        opportunityUid={opportunityUid}
        requirementRemovalType="DELETE"
        inBulkEditMode={inBulkEditMode}
        requirement={req}
        isChecked={checkedRequirements.some((_req) => _req.requirementUid === req.requirementUid)}
        onCheckRequirement={() => { handleCheckRequirement(req); }}
        isFocusedRequirement={focusedRequirementUid === req.requirementUid}
        onClickRequirement={() => { toggleFocusedRequirement(req.requirementUid); }}
        handleOpenEditRequirement={() => { onOpenEditRequirement(req); }}
        tooltipWidth={showDocuments ? 'calc(50vw - 180px)' : 'calc(100vw - 170px)'}
        shouldShowRequirementDropdown={true}
      />);
  };
  const [opportunityFileToAddTo, setOpportunityFileToAddTo] = useState<string>('');
  const [defaultRequirementText, setDefaultRequirementText] = useState<string>('');
  const onOpenAddRequirement = useCallback((requirementText?: string, opportunityFileUid?: string) => {
    setOpportunityFileToAddTo(opportunityFileUid ?? '');
    setDefaultRequirementText(requirementText ?? '');
    addRequirementHandlers.open();
  }, []);

  const toggleFileSectionOpen = useCallback((fileUid: string) => {
    if (openFileUids.includes(fileUid)) {
      setOpenFileUids(openFileUids.filter((uid) => uid !== fileUid));
    } else {
      setOpenFileUids([...openFileUids, fileUid]);
    }
  }, [openFileUids]);

  const getFileRequirementsSection = (file: OpportunityFile) => {
    const fileRequirements = unsortedRequirements?.filter((req) => req.requirement?.opportunity_file?.uid === file.uid);
    const noRequirementsInFile = fileRequirements?.length === 0;
    const isOpen = openFileUids.includes(file.uid ?? '');
    return (
      <Box key={file.file_name}>
        <Group
          onClick={() => { toggleFileSectionOpen(file.uid ?? ''); }}
          justify="space-between"
          pl={8}
          pb={9}
          pt={12}
        >
          <Group gap='xs' >
            <ActionIcon
              className={isOpen ? classes.chevron : ''}
              variant='subtle'
              size='xs'
              c='var(--mantine-color-darkPurple-9)'
            >
              <ArrowRight />
            </ActionIcon>
            <Text
              fw={500}
              ta='start'
            >
              {file.file_name}
            </Text>
          </Group>
          <FileRequirementsViewDropdown onOpenAddRequirement={() => { onOpenAddRequirement('', file.uid ?? ''); }} />
        </Group>
        <Divider />
        {isOpen && <Stack gap={0}>
          {noRequirementsInFile
            ? <Text p={8} ta={'center'}>
              {EMPTY_STATE_TEXT}
            </Text>
            : formatRequirementData(fileRequirements).map((req) => {
              return (
                <Box key={req.requirementResponseUid}>
                  {getRequirement(req)}
                  <Divider ml={20} color='var(--mantine-color-greyPurple-2)' />
                </Box>
              );
            })}
        </Stack>}
      </Box>);
  };

  const headerButtonGroup = (
    <Group justify='end'>
      <Button leftSection={<Star />} variant='outline' onClick={sortRequirementHandlers.open}>
        Autosort
      </Button>
      <ActionIcon
        size={36}
        c='var(--mantine-color-darkPurple-9)'
        variant='subtle'
        onClick={() => { setShowDocuments(!showDocuments); }}
      >
        <SplitView />
      </ActionIcon>
    </Group>
  );

  if (isLoading) {
    return <CenteredLoader h='calc(100vh - 300px)' />;
  }

  return (
    <Box>
      <Modal {...defaultModalProps} opened={editRequirementOpened}>
        <EditRequirement
          requirement={requirementToEdit}
          close={editRequirementHandlers.close}
        />
      </Modal>
      <Modal opened={addRequirementOpened} {...defaultModalProps}>
        <AddRequirement
          updateReduxOnSuccess
          opportunityUid={proposal?.opportunity?.uid ?? ''}
          defaultRequirementText={defaultRequirementText}
          close={addRequirementHandlers.close}
          opportunityFileUid={opportunityFileToAddTo}
        />
      </Modal>
      <Modal {...defaultModalProps} opened={deleteRequirementsOpened}>
        <DeleteRequirements
          onDeleteSuccess={() => { setCheckedRequirements([]); }}
          close={deleteRequirementsHandlers.close}
          requirements={checkedRequirements}
          opportunityUid={opportunityUid}
        />
      </Modal>
      <Modal {...defaultModalProps} opened={sortRequirementOpened}>
        <AutosortRequirements onClose={sortRequirementHandlers.close} proposalUid={proposalUid ?? ''} />
      </Modal>
      <Box display='flex' w='100%' h={'calc(100vh - 190px)'}>
        <Box p={16} w={showDocuments ? '50%' : '100%'}>
          <Group justify='space-between'>
            <Text fz={18}>
              Unsorted Requirements
            </Text>
            {inBulkEditMode
              ? <BulkRequirementButtonGroup
                requirementRemovalType='DELETE'
                onOpenDeleteRequirements={deleteRequirementsHandlers.open}
                sections={sections}
                checkedRequirements={checkedRequirements}
                onClearCheckedRequirements={() => { setCheckedRequirements([]); }} />
              : headerButtonGroup}
          </Group>
          <ScrollArea.Autosize mah='100%' pr={12}>
            <Stack>
              {sortedOpportunityFiles?.map((file) => getFileRequirementsSection(file))}
            </Stack>
          </ScrollArea.Autosize>
        </Box>
        {showDocuments &&
          <Box w='50%'>
            <OpportunityDocumentsViewer
              style={{ height: '100%' }}
              proposal={proposal}
              requirementResponses={unsortedRequirements}
              focusedRequirementUid={focusedRequirementUid}
              onClickHighlightedRequirement={(reqUid: string) => { setFocusedRequirementUid(reqUid); }}
              onAddRequirement={onOpenAddRequirement}
              currentFileIndex={currentFileIndex}
              onClickFile={(index: number) => { setCurrentFileIndex(index); }}
            />
          </Box>
        }
      </Box>
    </Box>);
};

export default RequirementsListByFile;
