import { type ResourceType } from '../types/miscTypes';

export const RESOURCE_TYPE_TO_STRING_MAP: Record<ResourceType, string> = {
  WON_PROPOSAL: 'Won Proposal',
  LOST_PROPOSAL: 'Lost Proposal',
  RESUME: 'Resume',
  PROPOSAL_IN_PROGRESS: 'Proposal in Progress',
  DEFAULT: 'Miscellaneous Document'
};

export enum OutlineOutputFormatType {
  BULLET_POINTS = 'BULLET_POINTS',
  PARAGRAPH = 'PARAGRAPH'
}

export enum ProposalStatusType {
  INITIALIZING = 'INITIALIZING',
  SETUP = 'SETUP',
  PROCESSING = 'PROCESSING',
  AUTHORING = 'AUTHORING',
  IN_REVIEW = 'IN_REVIEW',
  FINALIZING = 'FINALIZING',
  DONE = 'DONE',
};

export const PROPOSAL_STATUS_TO_STRING_MAP: Record<ProposalStatusType, string> = {
  INITIALIZING: 'Initializing',
  SETUP: 'Setup',
  PROCESSING: 'Processing',
  AUTHORING: 'Authoring',
  IN_REVIEW: 'In Review',
  FINALIZING: 'Finalizing',
  DONE: 'Done'
};

export const PROPOSAL_STATUS_TYPES_PRE_AUTHORING = [ProposalStatusType.SETUP, ProposalStatusType.PROCESSING];
