import { LoadingOverlay, Textarea } from '@mantine/core';
import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';

import ModalContent from '../ModalContent';
import useEnsisMutation from '../../hooks/useEnsisMutation';
import type { OpportunityRequirement } from '../../types/apiTypes';
import { type RequirementType } from '../../utils/requirementUtils';

interface Props {
  close: () => void
  requirement: RequirementType
  opportunityUid?: string
  onEditRequirement?: (req: OpportunityRequirement) => void
}

const EditRequirement: React.FC<Props> = (props: Props) => {
  const { proposalUid, sectionUid } = useParams();
  const { close, requirement, opportunityUid, onEditRequirement } = props;
  const [requirementIdentifier, setRequirementIdentifier] = useState(requirement.requirementIdentifier ?? '');
  const [requirementText, setRequirementText] = useState(requirement.requirementText ?? '');

  const editRequirementText = useEnsisMutation(
    `/app/requirements/${requirement.requirementUid}/data`,
    {
      requestType: 'patch',
      contentType: 'application/json',
      successMessage: 'Requirement updated',
      queryKeysToInvalidate: [
        `/app/proposals/${proposalUid}/sections/${sectionUid}/requirement-responses`,
        '/app/requirement-responses',
        `/app/opportunities/${opportunityUid}/requirements`
      ],
      onSuccess: close,
      awaitRefetch: false
    }
  );

  const handleEditRequirement = useCallback(() => {
    editRequirementText.mutate({
      text: requirementText,
      identifier: requirementIdentifier
    },
    {
      onSuccess: (data) => {
        if (onEditRequirement !== undefined) {
          onEditRequirement(data);
        }
      }
    });
  }, [editRequirementText.mutate, requirementText, onEditRequirement, requirementIdentifier]);

  const submitDisabled = (
    requirementText === requirement.requirementText && requirementIdentifier === requirement.requirementIdentifier
  ) || requirementText.length === 0;

  return (
    <ModalContent
      onClose={close}
      title='Edit requirement'
      primaryButton={{
        label: 'Confirm',
        onClick: handleEditRequirement,
        disabled: submitDisabled
      }}
      secondaryButton={{
        label: 'Cancel',
        onClick: close
      }}
    >
      <LoadingOverlay zIndex={1000} visible={editRequirementText.isPending} />
      <Textarea
        autosize
        mb={24}
        label='Identifier'
        placeholder='Leave blank if none'
        minRows={1}
        maxRows={4}
        value={requirementIdentifier}
        onChange={(event) => { setRequirementIdentifier(event.currentTarget.value); }}
      />
      <Textarea
        autosize
        label='Requirement'
        placeholder='New requirement'
        minRows={1}
        maxRows={4}
        value={requirementText}
        onChange={(event) => { setRequirementText(event.currentTarget.value); }}
      />
    </ModalContent>
  );
};

export default EditRequirement;
