import { useNavigate, useParams } from 'react-router-dom';

import { useCallback, useState } from 'react';

import { Stepper, Box, Group, ActionIcon, LoadingOverlay } from '@mantine/core';

import CreateProposal from './CreateProposal';
import SelectResources from './SelectResources';
import SelectRequirementPages from './SelectRequirementPages';

import classes from '../../components/Header/Header.module.css';
import { BackArrow } from '../../icons';
import useEnsisMutation from '../../hooks/useEnsisMutation';
import useEnsisQuery from '../../hooks/useEnsisQuery';

const CreateProposalWizard: React.FC = () => {
  const { proposalUid, organization } = useParams();
  const [currentStep, setCurrentStep] = useState(proposalUid === undefined ? 0 : 1);

  const navigate = useNavigate();

  const { data: proposal, isLoading: proposalLoading } = useEnsisQuery(
    `/app/proposals/${proposalUid}/data`, {
      enabled: proposalUid !== undefined
    }
  );

  const extractRequirementsMutation = useEnsisMutation(
    `/app/opportunities/${proposal?.opportunity?.uid ?? ''}/extract-requirements`, {
      showSuccessMessage: false,
      queryKeysToInvalidate: [`/app/proposals/${proposalUid}/data`, '/app/requirement-responses']
    }
  );

  const header = (
    <header>
      <Group justify='space-between' className={classes.headerRoot}>
        <ActionIcon
          variant='subtle'
          c='var(--mantine-color-darkPurple-9)'
          onClick={() => { navigate('/'); }}
        >
          <BackArrow />
        </ActionIcon>
        <Stepper active={currentStep}>
          <Stepper.Step label="Step 1" description="Upload files" />
          <Stepper.Step label="Step 2" description="Page ranges" />
          <Stepper.Step label="Step 3" description="Select resources" />
        </Stepper>
        <Box>
        </Box>
      </Group>
    </header >);

  const handleOnCreationDone = useCallback(() => {
    extractRequirementsMutation.mutate({ proposal_uid: proposalUid ?? null });
    navigate(`/org/${organization}/proposals/setup-proposal/${proposalUid}`);
  }, [extractRequirementsMutation]);

  if (proposalUid !== undefined && (proposalLoading || proposal === undefined)) {
    return <Box w='100%'>
      {header}
      <Box>
        <LoadingOverlay />
      </Box>
    </Box>;
  }

  const steps = [
    {
      component: <CreateProposal onDone={() => { setCurrentStep(1); }} />
    },
    {
      component: <SelectRequirementPages
        proposalUid={proposalUid ?? ''}
        onDone={() => { setCurrentStep(2); }}
      />
    },
    {
      component: <SelectResources
        onClickNext={handleOnCreationDone}
        onClickPrevious={() => { setCurrentStep(1); }}
        organizationSlug={organization ?? ''}
        proposal={proposal}
      />
    }
  ];

  return (
    <Box w='100%'>
      {header}
      <Box>
        {steps[currentStep].component}
      </Box>
    </Box>
  );
};
export default CreateProposalWizard;
