import { useCallback, useState } from 'react';
import { Box, Group, Modal, Title, Button, ScrollArea } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

import { useNavigate, useParams } from 'react-router-dom';

import { type Row } from '@tanstack/react-table';

import { CenteredLoader, Pagination, Table } from '../../components';
import { DeleteProposal, RenameProposal } from '../../components/Modals';
import useProposalTableData from '../../hooks/useProposalTableData';
import { type ProposalRow } from '../../types/tableTypes';
import useOrgPermissions from '../../hooks/useOrgPermissions';
import { formatOrganizationTitle } from '../../utils/stringUtils';
import TableEmptyState, { type TableEmptyStateProps } from '../../components/Table/TableEmptyState';
import useEnsisQuery from '../../hooks/useEnsisQuery';
import { defaultModalProps } from '../../utils/mantineUtils';
import { SIDE_MENU_WIDTH } from '../../components/SideMenu/SideMenu';
import { PROPOSAL_STATUS_TYPES_PRE_AUTHORING, ProposalStatusType } from '../../utils/constants';

const Dashboard: React.FC = () => {
  const { organization } = useParams();
  const { userHasPermission, organizationName, isLoading: permissionsLoading } = useOrgPermissions();
  const { data: meData, isLoading: meLoading } = useEnsisQuery('/app/users/me');
  const [renameProposalOpened, renameProposalHandlers] = useDisclosure();
  const [deleteProposalOpened, deleteProposalHandlers] = useDisclosure();
  const [currentProposal, setCurrentProposal] = useState<ProposalRow>();
  const navigate = useNavigate();

  const onRowClick = useCallback((row: Row<ProposalRow>) => {
    if (row.original.status === ProposalStatusType.INITIALIZING) {
      navigate(`create-proposal/${row.original.uid}`);
    } else if (PROPOSAL_STATUS_TYPES_PRE_AUTHORING.includes(row.original.status)) {
      navigate(`setup-proposal/${row.original.uid}`);
    } else {
      navigate(row.original.uid);
    }
  }, [navigate]);

  const handleOpenRenameProposal = (row: Row<ProposalRow>) => {
    setCurrentProposal(row.original);
    renameProposalHandlers.open();
  };

  const handleOpenDeleteProposal = (row: Row<ProposalRow>) => {
    setCurrentProposal(row.original);
    deleteProposalHandlers.open();
  };

  const handleNavigateToCreation = useCallback(() => {
    navigate('create-proposal');
  }, [navigate]);

  const { table, isLoading: tableLoading } = useProposalTableData({
    organizationSlug: organization,
    onRename: handleOpenRenameProposal,
    onDelete: handleOpenDeleteProposal
  });

  const isLoading = permissionsLoading || tableLoading || meLoading;
  const userCanCreateProposal = userHasPermission('create_proposal');
  const emptyStateProps: TableEmptyStateProps = {
    text: 'You haven\'t generated any proposals yet.',
    actionButton: {
      label: 'Start a proposal',
      onClick: handleNavigateToCreation
    }
  };

  return (<>
    {currentProposal !== undefined && (
      <>
        <Modal opened={renameProposalOpened} {...defaultModalProps}>
          <RenameProposal
            close={renameProposalHandlers.close}
            proposal={currentProposal}
          />
        </Modal>
        <Modal opened={deleteProposalOpened} {...defaultModalProps}>
          <DeleteProposal
            close={deleteProposalHandlers.close}
            proposalName={currentProposal.name}
            proposalUid={currentProposal.uid}
          />
        </Modal>
      </>
    )}
    <Box w={`calc(100vw - ${SIDE_MENU_WIDTH})`} px={48} pt={24}>
      <Group justify='space-between'>
        <Title order={4}> {`Welcome to Ensis, ${meData?.first_name}.`}</Title>
        {
          userCanCreateProposal &&
          <Button color='red' h='42' w='224' fz='md' onClick={handleNavigateToCreation}> Start a new proposal </Button>
        }
      </Group>
      <Title pt={20} ta={'start'} order={5}>
        {formatOrganizationTitle(organizationName ?? '', 'Proposals')}
      </Title>
      <ScrollArea style={{ height: 'calc(100vh - 255px)', maxHeight: '500px' }} >
        {
          isLoading
            ? <CenteredLoader style={{ height: 224 }} />
            : (
                table.getRowModel().rows.length === 0
                  ? <TableEmptyState {...emptyStateProps} />
                  : <Table table={table} onRowClick={onRowClick} />
              )
        }
      </ScrollArea>
      <Pagination table={table} />
    </Box>
  </>
  );
};

export default Dashboard;
