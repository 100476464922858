import { useParams } from 'react-router-dom';
import { Box, Group, Stack, Tabs, Text } from '@mantine/core';

import useEnsisQuery from '../../hooks/useEnsisQuery';
import { CenteredLoader, SelectProposalResources } from '../../components';
import SelectProposalSpacing from '../../components/SelectProposalSpacing';
import { formatDateFromString } from '../../utils/stringUtils';
import OpportunityFilesSection from './OpportunityFilesSection';
import ProposalOutlineWithDocumentViewer from '../../components/ProposalOutlineWithDocumentViewer';
import ProposalFactsSection from './ProposalFactsSection';
import { AdditionalProposalInfo } from './AdditionalProposalInfo';

const ManageProposalPage: React.FC = () => {
  const { proposalUid } = useParams();
  const { data, isLoading } = useEnsisQuery(`/app/proposals/${proposalUid}/data`);
  const opportunityUid = data?.opportunity.uid;
  const referenceUids = data?.references?.map((resource) => resource.uid) ?? [];

  if (isLoading || data === undefined) {
    return <CenteredLoader />;
  }

  return (
    <Box>
      <Box w={'100vw'}>
        <Group
          justify='flex-start'
          p={16}
          gap={32}
          bg='var(--mantine-color-greyPurple-0)'
        >
          <SelectProposalSpacing proposalUid={data?.uid ?? ''} />
          <Stack gap={8}>
            <Group style={{ alignSelf: 'flex-start' }}>
              <Text fz='sm' ta='start' fw={600}> Date Created </Text>
            </Group>
            <Text> {formatDateFromString(data?.created_at ?? undefined) ?? 'Date created unknown'} </Text>
          </Stack>
          <Stack gap={8}>
            <Text fz='sm' ta='start' fw={600}> Last Edited </Text>
            <Text> {formatDateFromString(data?.created_at ?? undefined) ?? 'Last edited unknown'} </Text>
          </Stack>
        </Group>
      </Box>
      <Tabs defaultValue='Outline'>
        <Tabs.List>
          <Tabs.Tab value='Opportunity Files'>
            Opportunity Files
          </Tabs.Tab>
          <Tabs.Tab value='Resources'>
            Resources
          </Tabs.Tab>
          <Tabs.Tab value='Outline'>
            Outline
          </Tabs.Tab>
          <Tabs.Tab value='Facts'>
            Facts
          </Tabs.Tab>
          <Tabs.Tab value='Win Themes / Differentiators'>
            Win Themes / Differentiators
          </Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value='Opportunity Files' p={16}>
          <OpportunityFilesSection opportunityUid={opportunityUid ?? ''} />
        </Tabs.Panel>
        <Tabs.Panel value='Resources' p={16}>
          <SelectProposalResources referenceUids={referenceUids} showButtons />
        </Tabs.Panel>
        <Tabs.Panel value='Outline' p={16}>
          <ProposalOutlineWithDocumentViewer
            proposal={data}
            sections={data.sections ?? []}
            requirementRemovalType='ARCHIVE'
            style={{ height: 'calc(100vh - 300px)' }}
          />
        </Tabs.Panel>
        <Tabs.Panel value='Facts' p={16}>
          <ProposalFactsSection />
        </Tabs.Panel>
        <Tabs.Panel value='Win Themes / Differentiators' p={16}>
          <AdditionalProposalInfo additionalContext={data?.additional_context} />
        </Tabs.Panel>
      </Tabs>
    </Box>
  );
};

export default ManageProposalPage;
