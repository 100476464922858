import { Button, Popover, Stack } from '@mantine/core';

import { useCallback, useState } from 'react';

import ProposalStatusItem from './ProposalStatusItem';
import useEnsisQuery from '../../../hooks/useEnsisQuery';
import { ProposalStatusType } from '../../../utils/constants';
import CenteredLoader from '../../CenteredLoader';
import useEnsisMutation from '../../../hooks/useEnsisMutation';
import { hasPermissionsForObject } from '../../../utils/apiUtils';
import classes from '../DropdownMenus.module.css';
import { getProposalStatus } from '../../../utils/formattingUtils';
import SetupProposalStatusDropdown from './SetupProposalStatusDropdown';

interface Props {
  proposalUid: string
  hovered: boolean
}

const EDITABLE_PROPOSAL_STATUSES = [
  ProposalStatusType.AUTHORING,
  ProposalStatusType.IN_REVIEW,
  ProposalStatusType.FINALIZING,
  ProposalStatusType.DONE
];

const ProposalStatusDropdown: React.FC<Props> = ({ proposalUid, hovered }: Props) => {
  const { data, isLoading } = useEnsisQuery(`/app/proposals/${proposalUid}/data`);
  const status = getProposalStatus(data);
  const [opened, setOpened] = useState(false);
  const hasEditPermission = hasPermissionsForObject(data, 'change');

  const statusCanBeChanged = EDITABLE_PROPOSAL_STATUSES.includes(status);
  const canOpenDropdown = statusCanBeChanged && hasEditPermission;

  const updateProposalStatusMutation = useEnsisMutation(`/app/proposals/${proposalUid}/data`,
    {
      requestType: 'patch',
      successMessage: 'Proposal status updated',
      queryKeysToInvalidate: [`/app/proposals/${proposalUid}/data`]
    });
  const handleOnChange = useCallback(() => {
    if (canOpenDropdown) {
      setOpened(!opened);
    }
  }, [opened, canOpenDropdown]);

  const onClick = useCallback((newStatus: string) => {
    updateProposalStatusMutation.mutate({
      status: newStatus
    });
    handleOnChange();
  }, [updateProposalStatusMutation, handleOnChange]);

  if (isLoading) {
    return <CenteredLoader />;
  }

  if (status === ProposalStatusType.SETUP) {
    return <SetupProposalStatusDropdown hovered={hovered} proposalUid={proposalUid}/>;
  }

  if (!statusCanBeChanged) {
    return <ProposalStatusItem status={status}/>;
  }

  return (
  <Popover classNames={{ dropdown: classes.popoverDropdown }} opened={opened} onChange={handleOnChange}>
    <Popover.Target>
      <Button
        classNames={{ root: hovered ? classes.buttonTargetRootHovered : classes.buttonTargetRoot }}
        variant='subtle'
        onClick={handleOnChange}
      >
        <ProposalStatusItem status={status}/>
      </Button>
    </Popover.Target>
    <Popover.Dropdown>
      <Stack ta='start' gap={2}>
      {EDITABLE_PROPOSAL_STATUSES.map((_status) => {
        return (
        <Button
          key={_status}
          classNames={{ root: classes.buttonItemRoot, inner: classes.buttonItems }}
          variant='subtle'
          onClick={() => { onClick(_status); }}
        >
          <ProposalStatusItem
            showCheckmark={_status === status}
            key={_status}
            status={_status as ProposalStatusType}
          />
        </Button>
        );
      })}
      </Stack>
    </Popover.Dropdown>
  </Popover>
  );
};
export default ProposalStatusDropdown;
