import { Text } from '@mantine/core';

import ModalContent from '../ModalContent';

interface Props {
  close: () => void
  onContinue: () => void
};

const MoveProposalToAuthoring: React.FC<Props> = ({ close, onContinue }: Props) => (
  <ModalContent
    title="Ready to Start Authoring?"
    primaryButton={{
      label: 'Continue',
      onClick: onContinue
    }}
    secondaryButton={{
      label: 'Cancel',
      onClick: close
    }}
  >
    <Text>
      Once you move to authoring, setup functionality is available from the Proposal Management page.
    </Text>
  </ModalContent>
);

export default MoveProposalToAuthoring;
