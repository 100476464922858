import { useCallback, useEffect, useState } from 'react';
import { Box, Text, Modal, Group, Center, ActionIcon, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useDispatch, useSelector } from 'react-redux';

import { useParams } from 'react-router-dom';

import type { Proposal, RequirementResponse, ProposalSection, WithoutPermissions } from '../../../types/apiTypes';
import { editorContentIsEmpty, getRequirementText } from '../../../utils/stringUtils';
import { defaultModalProps } from '../../../utils/mantineUtils';
import { EditRequirement, GenerateResponses } from '../../../components/Modals';
import { RequirementDropdown } from '../../../components/DropdownMenus';
import { type NarrativeOverlayState } from './EditorPane';
import OutlineResponseEditor from '../ResponseTextEditor/OutlineResponseEditor';
import { EditorPaneViewSelector, type ViewSelectorProps } from '../../../components';
import { selectNarrativeCompliance } from '../../../redux/store';
import { resetSelection, setMatches, updateSelectedUid } from '../../../redux/NarrativeComplianceSlice';
import useEnsisMutation from '../../../hooks/useEnsisMutation';
import {
  type RequirementType,
  getRequirementIdentifierSpan,
  DEFAULT_EMPTY_REQUIREMENT
} from '../../../utils/requirementUtils';
import { updateText as updateOutlineText } from '../../../redux/OutlineEditorSlice';
import { GenerateAll as GenerateAllIcon } from '../../../icons';
import { withAnalyticsEvent } from '../../../utils/analyticsUtils';
import { OutlineOutputFormatType } from '../../../utils/constants';
import SelectOutlineOutputFormat from '../../../components/Modals/SelectOutlineOutputFormat';

type Props = {
  section: WithoutPermissions<ProposalSection>
  proposal: Proposal
  requirementResponses: RequirementResponse[]
  canEdit: boolean
  narrativeOverlayState?: NarrativeOverlayState
  onComplianceModeClick: () => void
  complianceCheckIsRunning: boolean
  focusedRequirementUid: string
  onSelectRequirement?: (reqUid: string) => void
} & ViewSelectorProps;

/* eslint-disable-next-line max-len */
const EMPTY_STATE_COPY = 'This section has no requirements. Add requirements if needed, or generate narrative to draft content using AI.';
const OutlineEditor: React.FC<Props> = (props: Props) => {
  const {
    section,
    proposal,
    complianceCheckIsRunning,
    onComplianceModeClick,
    requirementResponses,
    canEdit,
    focusedRequirementUid,
    onSelectRequirement
  } = props;

  const { proposalUid, sectionUid } = useParams();
  const [currentRequirement, setCurrentRequirement] = useState<RequirementType>(DEFAULT_EMPTY_REQUIREMENT);
  const [editRequirementOpened, editRequirementHandlers] = useDisclosure();
  const [generateOpened, generateHandlers] = useDisclosure();
  const [selectFormatOpened, selectFormatHandlers] = useDisclosure();

  const dispatch = useDispatch();
  const sectionIsEmpty = (requirementResponses?.length ?? 0) === 0;
  const [shouldShowWarning, setShouldShowWarning] = useState(section.content !== null);

  const outlineOutputFormat = proposal.outline_output_format ?? OutlineOutputFormatType.BULLET_POINTS;
  const editRequirementComplianceMutation = useEnsisMutation('/app/requirement-responses', {
    requestType: 'patch',
    queryKeysToInvalidate: [`/app/proposals/${proposalUid}/sections/${sectionUid}/requirement-responses`],
    showSuccessMessage: false
  });

  const saveRequirementResponses = useEnsisMutation(
    '/app/requirement-responses',
    {
      contentType: 'application/json',
      requestType: 'patch',
      showSuccessMessage: false,
      queryKeysToInvalidate: [
        `/app/proposals/${proposalUid}/sections/${section.uid}/requirement-responses`
      ]
    }
  );

  useEffect(() => {
    setShouldShowWarning(section.content !== null);
  }, [section]);

  useEffect(() => {
    const reqMap: Record<string, string[]> = {};
    requirementResponses.forEach(
      (response) => { reqMap[response.uid] = response.section_text_references as string[] ?? []; }
    );
    dispatch(setMatches(
      { requirementToCompliantText: reqMap }
    ));
  }, [requirementResponses, sectionUid]);

  const canClickText = !complianceCheckIsRunning;

  const complianceSlice = useSelector(selectNarrativeCompliance);
  const selectedResponseUid = complianceSlice.selectedResponseUid;

  const handleComplianceTextClick = (responseUid: string) => {
    if (canClickText) {
      if (selectedResponseUid !== responseUid) {
        onComplianceModeClick();
        dispatch(updateSelectedUid({ responseUid }));
      } else {
        dispatch(resetSelection());
      }
    }
  };
  const handleNextSelectFormat = useCallback(() => {
    selectFormatHandlers.close();
    generateHandlers.open();
  }, []);

  const handleAcceptGenerateAll = useCallback(async (generatedTexts: Record<string, string>) => {
    const edits = Object.entries(generatedTexts).map(([uid, text]) => ({
      requirement_response_uid: uid,
      new_content: text
    }));
    await saveRequirementResponses.mutateAsync(
      {
        proposal_uid: proposalUid ?? '',
        edits
      }
    );
    // Update redux state
    // eslint-disable-next-line @typescript-eslint/naming-convention
    edits.forEach(({ requirement_response_uid, new_content }) => {
      dispatch(updateOutlineText({
        uid: requirement_response_uid,
        originalContent: new_content,
        content: new_content
      }));
    });
  }, [proposalUid, saveRequirementResponses.mutateAsync]);

  const getRequirementComponent = useCallback((response: RequirementResponse) => {
    const requirementText = response.requirement?.text ?? '';
    const requirementUid = response.requirement?.uid ?? '';
    const requirementIdentifier = response.requirement?.identifier ?? '';
    const isSelected = requirementUid !== '' && requirementUid === focusedRequirementUid;
    const onClickRequirement = () => {
      if (onSelectRequirement !== undefined) {
        onSelectRequirement(requirementUid);
      }
    };
    return (
    <Group
      justify='space-between'
      p='6.5px 16px'
      style={{
        backgroundColor: isSelected ? 'var(--mantine-color-green-1)' : undefined
      }}
    >
      <Text
        maw='calc(100% - 50px)'
        onClick={onClickRequirement}
        style={{ cursor: 'pointer' }}
      >
        {getRequirementIdentifierSpan(requirementIdentifier)}
        {getRequirementText(requirementText, sectionIsEmpty)}
      </Text>
      {canEdit && requirementText !== '' && (
        <RequirementDropdown
          sectionData={proposal.sections?.map((section) => {
            return {
              title: section?.title,
              uid: section?.uid,
              childSections: section?.child_sections,
              parentSectionUid: section?.parent_proposal_section_uid ?? undefined
            };
          }) ?? [{ title: '', uid: '' }]}
          handleOpenEditRequirement={() => {
            setCurrentRequirement({
              requirementText,
              requirementUid,
              requirementResponseUid: response.uid,
              requirementIdentifier,
              sectionUid: response.proposal_section?.uid ?? '',
              ordinal: response.ordinal ?? 1,
              updatedAt: response.updated_at ?? ''
            });
            editRequirementHandlers.open();
          }}
          requirementResponseOrdinal={response.ordinal ?? 1}
          requirementResponseUid={response.uid}
          sectionUid={sectionUid ?? ''}
          requirementRemovalType='DELETE'
          requirementUid={requirementUid}
          opportunityUid={proposal.opportunity?.uid ?? ''}
        />
      )}
    </Group>);
  }, [
    section,
    editRequirementHandlers,
    proposal,
    handleComplianceTextClick,
    sectionIsEmpty,
    canEdit,
    focusedRequirementUid,
    onSelectRequirement
  ]);

  const onEditRequirement = useCallback((requirementResponseUid: string) => {
    // mutatation clears compliance for edited requirement
    editRequirementComplianceMutation.mutate({
      proposal_uid: proposalUid ?? '',
      edits: [{
        requirement_response_uid: requirementResponseUid,
        section_text_references: [],
        is_compliant: false
      }]
    });
  }, [editRequirementComplianceMutation, proposalUid]);

  const outlineEmptyState = (
    <Center
      mt={8}
      style={{
        borderRadius: 4
      }}
      h={250}
      bg='var(--mantine-color-greyPurple-0)'
      ta='center'
    >
      <Text p={8}>
        {EMPTY_STATE_COPY}
      </Text>
    </Center>
  );

  const emptyResponses = requirementResponses.filter((response) => (
    editorContentIsEmpty(response.content)
  ));
  const hasEmptyResponses = emptyResponses.length > 0;

  const [responsesToGenerate, setResponsesToGenerate] = useState<RequirementResponse[]>(emptyResponses);

  const onClickGenerateAllResponses = useCallback(() => {
    setResponsesToGenerate(emptyResponses);
    selectFormatHandlers.open();
  }, [emptyResponses, selectFormatHandlers]);

  const onClickGenerateSingleResponse = useCallback((requirementResponse: RequirementResponse) => {
    setResponsesToGenerate([requirementResponse]);
    selectFormatHandlers.open();
  }, [selectFormatHandlers]);

  const generateAllIcon = (
    <Tooltip
      events={{
        hover: true,
        focus: false,
        touch: false
      }}
      label={hasEmptyResponses ? 'Generate all' : 'No responses to generate'}
    >
      <ActionIcon
        size={36}
        p={6}
        variant='subtle'
        onClick={withAnalyticsEvent(onClickGenerateAllResponses, 'GenerateAllResponses')}
        disabled={!hasEmptyResponses}
      >
        <GenerateAllIcon />
      </ActionIcon>
    </Tooltip>
  );

  return (
    <Box>
      <EditorPaneViewSelector
        {...props}
        rightSection={generateAllIcon}
      />
      <Box
        p='0px 8px'
        h={'calc(100vh - 210px)'}
        style={{ overflow: 'auto' }}
      >
        <Modal opened={editRequirementOpened} {...defaultModalProps}>
          <EditRequirement
            close={editRequirementHandlers.close}
            requirement={currentRequirement}
            onEditRequirement={() => { onEditRequirement(currentRequirement.requirementResponseUid); }}
          />
        </Modal>
        <Modal opened={selectFormatOpened} {...defaultModalProps} >
          <SelectOutlineOutputFormat
            onClickNext={handleNextSelectFormat}
            onClose={selectFormatHandlers.close}
            defaultOutlineOutputFormat={outlineOutputFormat}
          />
        </Modal>
        <Modal opened={generateOpened} {...defaultModalProps} size='90%'>
          <GenerateResponses
            onClose={generateHandlers.close}
            onSubmit={handleAcceptGenerateAll}
            requirementResponses={responsesToGenerate}
          />
        </Modal>
        {sectionIsEmpty
          ? outlineEmptyState
          : requirementResponses?.map((response) => {
            return (
            <Box
              key={response.uid}
              mt={8}
              style={{ border: '1px solid var(--mantine-color-gray-2)', borderRadius: '4px' }}
            >
              {getRequirementComponent(response)}
              <OutlineResponseEditor
                proposalUid={proposalUid ?? ''}
                canEdit={canEdit}
                responseData={response}
                shouldShowWarning={shouldShowWarning}
                setShouldShowWarningFalse={() => { setShouldShowWarning(false); } }
                outlineOutputFormat={outlineOutputFormat}
                onGenerateText={() => { onClickGenerateSingleResponse(response); }}
              />
            </Box>
            );
          })}
      </Box>
    </Box>
  );
};

export default OutlineEditor;
