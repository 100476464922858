import { ScrollArea, Box } from '@mantine/core';
import { useCallback, useState } from 'react';

import { CenteredLoader, SelectProposalResources } from '../../components';
import useEnsisMutation from '../../hooks/useEnsisMutation';
import useOrgPermissions from '../../hooks/useOrgPermissions';
import type { Proposal } from '../../types/apiTypes';
import WizardContent from './WizardContent';

interface Props {
  onClickNext: () => void
  onClickPrevious: () => void
  proposal?: Proposal
  organizationSlug: string
}

const SelectResources: React.FC<Props> = (props: Props) => {
  const { onClickNext, proposal, onClickPrevious } = props;

  const { isLoading: permissionsLoading } = useOrgPermissions();
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const referenceUids = proposal?.references?.map((resource) => resource.uid);

  const editProposalResources = useEnsisMutation(`/app/proposals/${proposal?.uid}/data`, {
    requestType: 'patch',
    successMessage: 'Resources updated',
    showSuccessMessage: false,
    queryKeysToInvalidate: ['/app/organization-resources', `/app/proposals/${proposal?.uid}/data`]
  });

  const isLoading = permissionsLoading || editProposalResources.isPending;

  const handleClickDone = useCallback(async () => {
    await editProposalResources.mutateAsync({
      reference_uids: selectedRows
    });
    onClickNext();
  }, [onClickNext, selectedRows, editProposalResources]);

  if (isLoading) {
    return <CenteredLoader style={{ height: '100vh', width: '100vw' }} />;
  };

  return (
    <Box w='100vw' pt={24}>
      <WizardContent
        titleCopy='Select your resources'
        subtitleCopy='Choose which of your resources are relevant to this proposal.'
        onClickNext={() => { void handleClickDone(); }}
        nextButtonDisabled={selectedRows.length === 0}
        onClickPrevious={onClickPrevious}
        nextButtonCopy='Done'
      >
        <ScrollArea h="calc(100vh - 250px)">
          <SelectProposalResources
            updateSelectedRows={(rows: string[]) => { setSelectedRows(rows); }}
            showButtons={false}
            referenceUids={referenceUids ?? []}
            tableContainerStyle={{ height: 'calc(100vh - 380px)', minHeight: 150 }}
          />
        </ScrollArea>
      </WizardContent>
    </Box>
  );
};

export default SelectResources;
