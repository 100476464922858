import Lottie from 'lottie-react';

import { Box, Button } from '@mantine/core';
import { useNavigate, useParams } from 'react-router-dom';
import { useCallback } from 'react';

import { checklistAnimation } from '../../assets/lottie';
import WizardContent from '../CreateProposalWizard/WizardContent';

const TITLE_COPY = 'Your opportunity is now processing';
/* eslint-disable-next-line max-len */
const SUBTITLE_COPY = 'This may take a few minutes. You can return to My Proposals, and we\'ll let you know when it\'s ready to edit via email';
const ProcessingScreen: React.FC = () => {
  const navigate = useNavigate();
  const { organization } = useParams();

  const onClick = useCallback(() => {
    navigate(`/org/${organization}/proposals`);
  }, [navigate]);

  return (
    <Box w='100vw' pt={24}>

  <WizardContent
    titleCopy={TITLE_COPY}
    subtitleCopy={SUBTITLE_COPY}
    onClickNext={() => {}}
    nextButtonDisabled={true}
  >
      <Lottie animationData={checklistAnimation} style={{ margin: 0, padding: 0, height: 250 }}/>
      <Button onClick={onClick}>
        Return to My Proposals
      </Button>
  </WizardContent>
  </Box>
  );
};

export default ProcessingScreen;
