import { configureStore } from '@reduxjs/toolkit';

import outlineEditorReducer from './OutlineEditorSlice';
import outlineReducer from './OutlineSlice';
import narrativeEditorReducer from './NarrativeEditorSlice';
import narrativeComplianceReducer from './NarrativeComplianceSlice';

const store = configureStore({
  reducer: {
    narrativeEditor: narrativeEditorReducer,
    outlineEditor: outlineEditorReducer,
    outline: outlineReducer,
    narrativeCompliance: narrativeComplianceReducer
  }
});

export type RootState = ReturnType<typeof store.getState>;

export const selectNarrativeCompliance = (state: RootState) => state.narrativeCompliance;
export const selectUpdatedOutlineText = (state: RootState) => state.outlineEditor;
export const selectOutlineIsDirty = (state: RootState) => (
  Object.values(state.outlineEditor).some((value) => value !== undefined)
);
export const selectUpdatedNarrativeText = (state: RootState) => state.narrativeEditor;
export const selectNarrativeIsDirty = (state: RootState) => state.narrativeEditor.updatedContent !== undefined;
export const selectOutline = (state: RootState) => state.outline;

export default store;
