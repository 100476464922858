import { Box, Button, Group, Text, ActionIcon, Modal, ScrollArea, type MantineStyleProp } from '@mantine/core';

import { useState, useCallback, useMemo } from 'react';

import { useDisclosure } from '@mantine/hooks';

import { useParams } from 'react-router-dom';

import { useDispatch } from 'react-redux';

import EditOutlineDnD from './EditOutlineDnd';
import useEnsisQuery from '../../hooks/useEnsisQuery';
import { type SectionData } from '../../Pages/Editor/SectionEditor';
import { AddRequirement, AddSection } from '../Modals';

import { defaultModalProps } from '../../utils/mantineUtils';

import CenteredLoader from '../CenteredLoader';
import { type Proposal } from '../../types/apiTypes';
import { SplitView } from '../../icons';
import BulkRequirementButtonGroup from '../BulkRequirementButtonGroup';
import { formatSectionDataForRedux } from '../../utils/dndUtils';

import { addSection } from '../../redux/OutlineSlice';
import DeleteRequirements from '../Modals/DeleteRequirements';
import { type RequirementType, type RequirementRemovalType } from '../../utils/requirementUtils';
import OutlineDisplayOptions from '../DropdownMenus/OutlineDisplayOptions';
import { OpportunityDocumentsViewer } from '../OpportunityDocumentsViewer';

interface Props {
  proposal: Proposal
  sections: SectionData[]
  requirementRemovalType: RequirementRemovalType
  isSetup?: boolean
  style?: MantineStyleProp
}

const ProposalOutlineWithDocumentViewer: React.FC<Props> = (props: Props) => {
  const { proposal, sections, style, requirementRemovalType, isSetup = false } = props;
  const { proposalUid } = useParams();
  const [focusedRequirementUid, setFocusedRequirementUid] = useState('');
  const [showDocumentsViewer, setShowDocumentsViewer] = useState(true);
  const [showAssignedUsers, setShowAssignedUsers] = useState(false);
  const [showRequirements, setShowRequirements] = useState(true);
  const [parentSectionUid, setParentSectionUid] = useState<string | undefined>(undefined);
  const [currentFileIndex, setCurrentFileIndex] = useState(0);
  const { data: requirementResponseData, isLoading: requirementResponseLoading } = useEnsisQuery(
    '/app/requirement-responses',
    {
      queryParams: { proposal_uid: proposalUid }
    }
  );

  const [opportunityFileToAddTo, setOpportunityFileToAddTo] = useState<string>('');
  const [defaultRequirementText, setDefaultRequirementText] = useState<string>('');
  const onOpenAddRequirement = useCallback((requirementText?: string, opportunityFileUid?: string) => {
    setOpportunityFileToAddTo(opportunityFileUid ?? '');
    setDefaultRequirementText(requirementText ?? '');
    addRequirementHandlers.open();
  }, []);

  const toggleFocusedRequirement = useCallback((requirementUid: string) => {
    if (focusedRequirementUid === requirementUid) {
      setFocusedRequirementUid('');
    } else {
      setFocusedRequirementUid(requirementUid);
    }
  }, [focusedRequirementUid]);
  const [addSectionOpened, addSectionHandlers] = useDisclosure();
  const [addRequirementOpened, addRequirementHandlers] = useDisclosure();
  const [deleteRequirementsOpened, deleteRequirementsHandlers] = useDisclosure();
  const [checkedRequirements, setCheckedRequirements] = useState<RequirementType[]>([]);

  const handleCheckRequirement = useCallback((req: RequirementType) => {
    if (checkedRequirements.some((_req) => _req.requirementUid === req.requirementUid)) {
      setCheckedRequirements(checkedRequirements.filter((_req) => _req !== req));
    } else {
      setCheckedRequirements([...checkedRequirements, req]);
    }
  }, [checkedRequirements]);

  const actionButtons = (
    <Group justify='end'>
      <Button variant='subtle' onClick={() => { onOpenAddSection(undefined); }}>
        Add Section
      </Button>
      <OutlineDisplayOptions
        showRequirementsSelected={showRequirements}
        toggleShowRequirements={() => { setShowRequirements(!showRequirements); }}
        showUsersSelected={showAssignedUsers}
        toggleShowUsers={() => { setShowAssignedUsers(!showAssignedUsers); }}
      />
      <ActionIcon
        size={36}
        variant='subtle'
        c='var(--mantine-color-darkPurple-9)'
        onClick={() => { setShowDocumentsViewer(!showDocumentsViewer); }}
      >
        <SplitView />
      </ActionIcon>
    </Group>
  );
  const dispatch = useDispatch();

  const requirementsToHighlight = useMemo(() => {
    if (isSetup) {
      return requirementResponseData?.items?.filter((req) => req?.proposal_section !== null) ?? [];
    }
    return requirementResponseData?.items ?? [];
  }, [requirementResponseData?.items]);

  const onOpenAddSection = useCallback((parentSectionUid?: string) => {
    setParentSectionUid(parentSectionUid);
    addSectionHandlers.open();
  }, []);
  const onAddSectionSuccess = useCallback((data: any) => {
    const formattedData = formatSectionDataForRedux(data);
    dispatch(addSection(formattedData));
  }, []);

  const inBulkEditMode = checkedRequirements.length > 0;

  if (requirementResponseLoading) {
    return <CenteredLoader h='calc(100vh - 300px)' />;
  }
  return (
    <Box>
      <Modal opened={addSectionOpened} {...defaultModalProps}>
        <AddSection
          parentSectionUid={parentSectionUid}
          close={addSectionHandlers.close}
          sections={sections}
          onAddSectionSuccess={onAddSectionSuccess}
        />
      </Modal>
      <Modal opened={addRequirementOpened} {...defaultModalProps}>
        <AddRequirement
          opportunityUid={proposal?.opportunity?.uid ?? ''}
          defaultRequirementText={defaultRequirementText}
          close={addRequirementHandlers.close}
          opportunityFileUid={opportunityFileToAddTo}
        />
      </Modal>
      <Modal opened={deleteRequirementsOpened} {...defaultModalProps}>
        <DeleteRequirements
          opportunityUid={proposal.opportunity?.uid ?? ''}
          requirements={checkedRequirements}
          onDeleteSuccess={() => { setCheckedRequirements([]); }}
          close={deleteRequirementsHandlers.close}
        />
      </Modal>
      <Box display='flex' w='100%' style={{ ...style }}>
        <Box w={showDocumentsViewer ? '50%' : '100%'} p={16}>
          <Group justify='space-between' pb={16}>
            <Text fz={18}>
              Outline
            </Text>
            {inBulkEditMode
              ? <BulkRequirementButtonGroup
                requirementRemovalType={requirementRemovalType}
                checkedRequirements={checkedRequirements}
                sections={sections}
                onClearCheckedRequirements={() => { setCheckedRequirements([]); }}
                onOpenDeleteRequirements={deleteRequirementsHandlers.open}
              />
              : actionButtons
            }
          </Group>
          <ScrollArea.Autosize mah='100%'>
            <EditOutlineDnD
              opportunityUid={proposal.opportunity?.uid ?? ''}
              showRequirements={showRequirements}
              onRowClick={toggleFocusedRequirement}
              focusedRequirementUid={focusedRequirementUid}
              sectionWidth={showDocumentsViewer ? 'calc(50vw - 100px)' : 'calc(100vw - 100px)'}
              sections={sections}
              requirementResponses={requirementResponseData?.items ?? []}
              onOpenAddSection={onOpenAddSection}
              checkedRequirements={checkedRequirements}
              onCheckRequirement={handleCheckRequirement}
              showAssignedUsers={showAssignedUsers}
              requirementRemovalType={requirementRemovalType}
            />
          </ScrollArea.Autosize>
        </Box>
        {showDocumentsViewer &&
          <Box w={'50%'}>
            <OpportunityDocumentsViewer
              style={{ height: '100%' }}
              proposal={proposal}
              currentFileIndex={currentFileIndex}
              onClickFile={(index: number) => { setCurrentFileIndex(index); }}
              requirementResponses={requirementsToHighlight}
              focusedRequirementUid={focusedRequirementUid}
              onClickHighlightedRequirement={(reqUid: string) => { toggleFocusedRequirement(reqUid); }}
              onAddRequirement={isSetup ? undefined : onOpenAddRequirement}
            />
          </Box>
        }
      </Box>
    </Box>);
};

export default ProposalOutlineWithDocumentViewer;
