import { useCallback, useState } from 'react';

import { useParams } from 'react-router-dom';

import { LoadingOverlay, Textarea } from '@mantine/core';

import { useDispatch } from 'react-redux';

import ModalContent from '../ModalContent';
import useEnsisMutation from '../../hooks/useEnsisMutation';
import { isEmpty } from '../../utils/stringUtils';
import { addRequirement } from '../../redux/OutlineSlice';
import { type RequirementType } from '../../utils/requirementUtils';

interface Props {
  close: () => void
  sectionToEditUid?: string
  defaultRequirementText?: string
  defaultRequirementIdentifier?: string
  onAddRequirement?: (requirementUid: string) => void
  updateReduxOnSuccess?: boolean
  opportunityUid?: string
  opportunityFileUid?: string
}

const AddRequirement: React.FC<Props> = (props: Props) => {
  const {
    close,
    sectionToEditUid,
    defaultRequirementText,
    opportunityUid,
    onAddRequirement,
    defaultRequirementIdentifier,
    opportunityFileUid,
    updateReduxOnSuccess = false
  } = props;

  const { proposalUid } = useParams();
  const [requirementIdentifier, setRequirementIdentifier] = useState(defaultRequirementIdentifier ?? '');
  const [requirementText, setRequirementText] = useState(defaultRequirementText ?? '');
  const dispatch = useDispatch();

  const addRequirementMutation = useEnsisMutation(
    '/app/requirement-responses',
    {
      contentType: 'application/json',
      requestType: 'post',
      successMessage: 'Requirement successfully added',
      onSuccess: (data) => {
        close();
        if (onAddRequirement !== undefined) {
          onAddRequirement(data?.requirement?.uid ?? '');
        }
      },
      awaitRefetch: false,
      queryKeysToInvalidate: [
        '/app/requirement-responses',
        `/app/proposals/${proposalUid}/sections/${sectionToEditUid}/requirement-responses`,
        `/app/opportunities/${opportunityUid}/requirements`
      ]
    }
  );

  const handleAddRequirement = useCallback(() => {
    addRequirementMutation.mutate({
      proposal_section_uid: sectionToEditUid ?? null,
      proposal_uid: proposalUid ?? '',
      requirement_text: requirementText,
      requirement_identifier: requirementIdentifier,
      opportunity_file_uid: opportunityFileUid ?? null
    }, {
      onSuccess: (data) => {
        if (updateReduxOnSuccess) {
          const formattedRequirement: RequirementType = {
            requirementResponseUid: data.uid,
            requirementText: data.requirement?.text ?? '',
            requirementUid: data.requirement?.uid ?? '',
            requirementIdentifier: data.requirement?.identifier ?? '',
            ordinal: data.ordinal ?? 1,
            updatedAt: data.updated_at ?? '',
            sectionUid: data.proposal_section?.uid ?? 'null'
          };
          dispatch(addRequirement(formattedRequirement));
        }
      }
    });
  }, [
    addRequirementMutation.mutate,
    requirementText,
    requirementIdentifier,
    proposalUid,
    sectionToEditUid
  ]);

  return (
    <ModalContent
      title='Add a requirement'
      subtitle="Enter your requirement below (you can change it later)."
      onClose={close}
      secondaryButton={{
        label: 'Cancel',
        onClick: close
      }}
      primaryButton={{
        label: 'Add',
        onClick: handleAddRequirement,
        disabled: isEmpty(requirementText)
      }}
    >
      <LoadingOverlay zIndex={1000} visible={addRequirementMutation.isPending}/>
      <Textarea
        autosize
        mb={16}
        label='Identifier'
        placeholder='Leave blank if none'
        minRows={1}
        maxRows={4}
        value={requirementIdentifier}
        onChange={(event) => { setRequirementIdentifier(event.currentTarget.value); }}
      />
      <Textarea
        autosize
        label='Requirement'
        placeholder='New requirement'
        minRows={1}
        maxRows={4}
        value={requirementText}
        onChange={(event) => { setRequirementText(event.currentTarget.value); }}
      />
    </ModalContent>
  );
};

export default AddRequirement;
