import { ActionIcon, Box, Group, Stack, Tooltip } from '@mantine/core';

import { ExitApp, ExpandRight, FormatListChecks } from '../../icons';
import { RouterNavLink } from '../../components';

interface Props {
  proposalManagementURL: string
  allProposalsURL: string
  onClickExpand: () => void
}

const CollapsedEditorSideMenu: React.FC<Props> = (props: Props) => {
  const {
    proposalManagementURL,
    allProposalsURL,
    onClickExpand
  } = props;

  return (
    <Box
      style={{ display: 'flex', flexDirection: 'column', height: 'calc(100vh - 64px)' }}
      pt={24}
      pb={24}
      w={50}
      bg='var(--mantine-color-greyPurple-0)'
    >
      <Group justify='center'>
        <ActionIcon variant='subtle' size='lg' onClick={onClickExpand}>
          <ExpandRight />
        </ActionIcon>
      </Group>
      <Stack justify='flex-end' flex={1} gap="lg">
        <RouterNavLink
          to={proposalManagementURL}
          pl={8}
          leftSection={
            <Tooltip label='Proposal Management'>
              <ActionIcon variant='subtle' size='lg'>
                <FormatListChecks />
              </ActionIcon>
            </Tooltip>
          }
        />
        <RouterNavLink
          to={allProposalsURL}
          pl={8}
          leftSection={
            <Tooltip label='Exit Proposal'>
              <ActionIcon variant='subtle' size='lg'>
                <ExitApp />
              </ActionIcon>
            </Tooltip>
          }
        />
      </Stack>
    </Box>
  );
};

export default CollapsedEditorSideMenu;
