import { useCallback, useImperativeHandle, useRef, useMemo, useState, useEffect } from 'react';
import { Box, type MantineStyleProp, Select } from '@mantine/core';

import { type DocumentLoadEvent } from '@react-pdf-viewer/core';

import OpportunityFileViewer from './OpportunityFileViewer';
import type { OpportunityFileViewerRef } from './OpportunityFileViewer';
import type { OpportunityRequirement, Proposal, RequirementResponse } from '../../types/apiTypes';
import useEnsisQuery from '../../hooks/useEnsisQuery';
import { sortOpportunityFilesByRequirementCount } from '../../utils/requirementUtils';
import { PDF_LOAD_DELAY_MS } from '../../utils/pdfUtils';

export interface RequirementMatchData {
  requirementUid: string
  pageNumber: number
}

interface OpportunityDocumentsViewerProps {
  proposal: Proposal
  requirementResponses: RequirementResponse[]
  currentFileIndex: number
  onClickFile: (fileIndex: number) => void
  focusedRequirementUid: string
  onAddRequirement?: (requirementText: string, opportunityFileUid?: string) => void
  onClickHighlightedRequirement?: (requirementUid: string) => void
  style?: MantineStyleProp
  onStartDocumentLoad?: () => void
  onFinishDocumentLoad?: (e?: DocumentLoadEvent) => void
}

export interface OpportunityDocumentsViewerRef {
  jumpToPage: (pageIndex: number) => void
}

const OpportunityDocumentsViewer: React.FC<OpportunityDocumentsViewerProps> = (
  props: OpportunityDocumentsViewerProps
) => {
  const {
    proposal,
    focusedRequirementUid,
    currentFileIndex,
    onClickFile,
    onClickHighlightedRequirement,
    onAddRequirement,
    requirementResponses,
    style,
    onStartDocumentLoad,
    onFinishDocumentLoad
  } = props;

  const [matches, setMatches] = useState<RequirementMatchData[]>([]);

  const { data: opportunityFiles } = useEnsisQuery(
    '/app/opportunity-files',
    {
      queryParams: { opportunity_uid: proposal?.opportunity?.uid ?? '', generate_download_url: true }
    }
  );

  const requirements: OpportunityRequirement[] = useMemo(() => (
    requirementResponses
      .filter((
        reqResponse => reqResponse?.requirement !== undefined && reqResponse?.requirement != null
      ))
      .map(
        reqResponse => reqResponse.requirement as OpportunityRequirement
      )
  ), [requirementResponses]);

  const sortedOpportunityFiles = useMemo(() => (
    sortOpportunityFilesByRequirementCount(
      opportunityFiles?.items ?? [],
      requirements
    )
  ), [opportunityFiles?.items]);

  const fileViewerRef = useRef<OpportunityDocumentsViewerRef>(null);

  const fileViewerRefs = useRef<Array<OpportunityFileViewerRef | null>>(
    new Array<OpportunityFileViewerRef | null>(sortedOpportunityFiles.length)
  );

  useEffect(() => {
    const selectedReq = requirements?.find((req) => req.uid === focusedRequirementUid);
    const fileIndex = sortedOpportunityFiles
      ?.findIndex((file) => file.uid === selectedReq?.opportunity_file?.uid) ?? -1;
    if (fileIndex === currentFileIndex) {
      jumpToRequirement(focusedRequirementUid, matches);
    } else if (fileIndex !== -1 && fileIndex !== currentFileIndex) {
      onClickFile(fileIndex);
    }
  }, [focusedRequirementUid]);

  // Jump to currently selected requirement every time matches are re-fetched
  useEffect(() => {
    setTimeout(() => {
      if (focusedRequirementUid !== '') {
        jumpToRequirement(focusedRequirementUid, matches);
      }
    }, PDF_LOAD_DELAY_MS);
  }, [matches]);

  const jumpToRequirement = useCallback((requirementUid: string, _matches: RequirementMatchData[]) => {
    const match = _matches.find((match) => match.requirementUid === requirementUid);
    const pageNumber = match?.pageNumber;
    if (pageNumber !== undefined) {
      fileViewerRef?.current?.jumpToPage(pageNumber);
    }
  }, [fileViewerRef]);

  useImperativeHandle(fileViewerRef, () => ({
    jumpToPage: (pageIndex: number) => {
      fileViewerRefs?.current[currentFileIndex]?.jumpToPage(pageIndex);
    }
  }));

  const handleGetMatches = useCallback((newMatches: RequirementMatchData[]) => {
    setMatches(newMatches);
  }, []);

  const getFileDropdown = useCallback(() => (
    <Select
      w='100%'
      p='8px 12px'
      comboboxProps={{ offset: 0, dropdownPadding: 0 }}
      styles={{
        input: {
          border: '1px var(--mantine-color-gray-3) solid',
          borderRadius: 4,
          opacity: 1,
          backgroundColor: 'white'
        },
        dropdown: {
          borderRadius: 4
        },
        option: {
          borderRadius: 4
        }
      }}
      data={sortedOpportunityFiles.map((file, index) => ({ value: index.toString(), label: file.file_name }))}
      value={currentFileIndex.toString()}
      onOptionSubmit={(value: string) => { onClickFile(parseInt(value)); }}
    />
  ), [opportunityFiles, currentFileIndex, onClickFile]);

  const selectedOpportunityFile = sortedOpportunityFiles[currentFileIndex];

  const currentFileRequirements = useMemo(() => (
    requirements.filter(
      (req) => req?.opportunity_file?.uid === selectedOpportunityFile?.uid
    )
  ), [selectedOpportunityFile, requirements]);

  const viewers = sortedOpportunityFiles.map((file, index) => (
    <OpportunityFileViewer
      key={file.uid}
      ref={(el) => { fileViewerRefs.current[index] = el; }}
      requirements={currentFileRequirements}
      opportunityFile={file}
      focusedRequirementUid={focusedRequirementUid ?? ''}
      onAddRequirement={onAddRequirement}
      onClickHighlightedRequirement={onClickHighlightedRequirement}
      onGetMatches={handleGetMatches}
      onStartDocumentLoad={onStartDocumentLoad}
      onFinishDocumentLoad={onFinishDocumentLoad}
    />
  ));

  return (
    <Box style={{ ...style, backgroundColor: 'var(--mantine-color-greyPurple-1)' }}>
      <Box style={{ display: 'flex', flexDirection: 'row' }}>
        {getFileDropdown()}
      </Box>
      { viewers[currentFileIndex] }
    </Box>
  );
};

export default OpportunityDocumentsViewer;
