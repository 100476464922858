import { Button, Checkbox, Text, Popover, Stack } from '@mantine/core';
import { useState } from 'react';

import classes from './DropdownMenus.module.css';
import { DisplayOptions } from '../../icons';

interface Props {
  showUsersSelected: boolean
  toggleShowUsers: () => void
  showRequirementsSelected: boolean
  toggleShowRequirements: () => void
}

const OutlineDisplayOptions: React.FC<Props> = (props: Props) => {
  const { showUsersSelected, toggleShowUsers, showRequirementsSelected, toggleShowRequirements } = props;
  const [opened, setOpened] = useState<boolean>(false);

  const showUsersRow = (
    <Button
      variant='subtle'
      classNames={{ root: classes.buttonItemRoot, inner: classes.buttonItems }}
      onClick={toggleShowUsers}
    >
      <Checkbox
        radius={4}
        mr={8}
        readOnly
        checked={showUsersSelected}
      />
      <Text> Show Assigned Users </Text>
    </Button>
  );

  const showRequirementsRow = (
    <Button
      variant='subtle'
      classNames={{ root: classes.buttonItemRoot, inner: classes.buttonItems }}
      onClick={toggleShowRequirements}
    >
      <Checkbox
        radius={4}
        mr={8}
        readOnly
        checked={showRequirementsSelected}
      />
      <Text> Show Requirements </Text>
    </Button>
  );

  return (
    <Popover
      position='bottom-start'
      onClose={() => { setOpened(false); }}
      opened={opened}
      classNames={{ dropdown: classes.popoverDropdown }}
    >
      <Popover.Target>
        <Button
          leftSection={<DisplayOptions />}
          variant='outline'
          onClick={() => { setOpened((o) => !o); }}
        >
          Display Options
        </Button>
      </Popover.Target>
      <Popover.Dropdown>
        <Stack gap={0}>
          {showUsersRow}
          {showRequirementsRow}
        </Stack>
      </Popover.Dropdown>
    </Popover>
  );
};

export default OutlineDisplayOptions;
