import { useNavigate, useParams } from 'react-router-dom';

import { useEffect, useState } from 'react';

import { Box, Tabs } from '@mantine/core';

import useEnsisQuery from '../../hooks/useEnsisQuery';
import { showFailureNotification } from '../../utils/mantineUtils';
import ProcessingScreen from './ProcessingScreen';
import { CenteredLoader } from '../../components';

import ProposalOutlineWithDocumentViewer from '../../components/ProposalOutlineWithDocumentViewer';
import RequirementsListByFile from './RequirementsListByFile';
import { formatSectionsMetadata } from '../../utils/apiUtils';

const DATA_REFETCH_INTERVAL_MS = 10000;

const SetupProposal: React.FC = () => {
  const { proposalUid, organization } = useParams();

  const [refetchingEnabled, setRefetchingEnabled] = useState(true);
  const { data: proposal, isLoading: proposalLoading } = useEnsisQuery(
    `/app/proposals/${proposalUid}/data`, {
      refetchInterval: refetchingEnabled ? DATA_REFETCH_INTERVAL_MS : undefined
    }
  );
  const navigate = useNavigate();
  const isProcessing = proposal?.opportunity?.processing_status === 'PROCESSING';
  const proposalHasSections = (proposal?.sections?.length ?? 0) > 0;

  const isLoading = (
    proposalLoading ||
    proposal?.sections === undefined ||
    !proposalHasSections
  );

  useEffect(() => {
    if (proposal?.opportunity?.processing_status === 'FAILED') {
      showFailureNotification('Proposal processing failed. Check your email for more information.');
      navigate(`/org/${organization}/proposals`);
    }
    if (proposal?.opportunity?.processing_status === 'PROCESSING') {
      setRefetchingEnabled(true);
    } else {
      setRefetchingEnabled(false);
    }
  }, [proposal?.opportunity?.processing_status]);

  const formattedSections = formatSectionsMetadata(proposal?.sections ?? []);
  if (isProcessing) {
    return <ProcessingScreen />;
  };

  if (isLoading || proposal.sections === undefined) {
    return <CenteredLoader h='calc(100vh - 300px)' />;
  };

  return (
    <Box ta='start' w={'100vw'}>
      <Tabs w='100%' defaultValue='requirements'>
        <Tabs.List mih={46}>
          <Tabs.Tab value='requirements'>
            Requirements
          </Tabs.Tab>
          <Tabs.Tab value='outline'>
            Outline
          </Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="requirements">
          <RequirementsListByFile
            sections={formattedSections}
            proposal={proposal}
            opportunityUid={proposal.opportunity.uid}
          />
        </Tabs.Panel>
        <Tabs.Panel value="outline">
          <ProposalOutlineWithDocumentViewer
            isSetup
            requirementRemovalType="DELETE"
            sections={formattedSections}
            proposal={proposal}
            style={{ height: 'calc(100vh - 190px)' }}
          />
        </Tabs.Panel>
      </Tabs>
    </Box>);
};

export default SetupProposal;
