import { Popover, ActionIcon, Button, Divider, Stack, Text, Anchor, Tooltip, Menu, ScrollArea } from '@mantine/core';

import { useCallback, useMemo, useState } from 'react';

import { useParams } from 'react-router-dom';

import { notifications } from '@mantine/notifications';
import type { AxiosError } from 'axios';
import { useDispatch, useSelector } from 'react-redux';

import {
  ThreeDotsHorizontal,
  ThreeDotsVertical,
  Pen,
  FileUploadIcon,
  Trash,
  ArrowRight
} from '../../icons';
import classes from './DropdownMenus.module.css';
import useEnsisMutation from '../../hooks/useEnsisMutation';

import { type RouteWithGet } from '../../types/apiTypes';
import { selectOutline, selectUpdatedOutlineText } from '../../redux/store';
import { deleteRequirement, moveRequirement, updateOutlineRequirements } from '../../redux/OutlineSlice';
import { handlePotentialLockError } from '../../utils/apiUtils';
import { ARCHIVED_SECTION_DATA } from '../../utils/stringUtils';
import { type SectionData } from '../../Pages/Editor/SectionEditor';
import { type RequirementRemovalType } from '../../utils/requirementUtils';

interface Props {
  handleOpenEditRequirement: () => void
  requirementResponseUid: string
  requirementResponseOrdinal: number
  requirementUid: string
  sectionData: SectionData[]
  sectionUid: string
  opportunityUid: string
  requirementRemovalType?: RequirementRemovalType
  isOutline?: boolean
}

const buttonClassNames = {
  inner: classes.popoverMenuButtonItem,
  section: classes.popoverMenuButtonLeftSection,
  root: classes.popoverMenuButtonItemRoot
};

interface MoveRequirementProps {
  onClick: (sectionUid: string) => void
  topLevelSections: SectionData[]
  sectionUidToDisable?: string
}

export const MoveRequirementMenu: React.FC<MoveRequirementProps> = (props: MoveRequirementProps) => {
  const { onClick, topLevelSections, sectionUidToDisable } = props;
  const getSectionItem = (section: SectionData, shouldDisable: boolean) => (
    <Button
      justify='start'
      h={56}
      classNames={buttonClassNames}
      key={section.uid}
      variant="subtle"
      disabled={shouldDisable && (section.uid === sectionUidToDisable)}
      onClick={() => { onClick(section.uid); }}
      rightSection={!shouldDisable && <ArrowRight/>}
    >
      {section.title}
    </Button>);

  const getSectionPopover = (section: SectionData) => {
    return (
      <Menu key={`popover-${section.uid}`} shadow="md" trigger='hover' position='left'>
        <Menu.Target>
          {getSectionItem(section, false)}
        </Menu.Target>
        <Menu.Dropdown style={{ margin: 0, padding: 0, borderRadius: 8 }}>
          <Stack gap={2} mt={10} mb={10} p={0} miw={150}>
            {section.childSections?.map((childSection) => {
              return getSectionItem(childSection, true);
            })}
          </Stack>
        </Menu.Dropdown>
      </Menu>
    );
  };

  return (
    <ScrollArea.Autosize mah={600}>
      <Stack gap={2} mt={10} mb={10}>
        {topLevelSections.map((section) => {
          if (section.childSections === undefined || section.childSections.length === 0) {
            return getSectionItem(section, true);
          };
          return getSectionPopover(section);
        })}
      </Stack>
    </ScrollArea.Autosize>
  );
};

const RequirementDropdown: React.FC<Props> = (props: Props) => {
  const { proposalUid } = useParams();
  const {
    handleOpenEditRequirement,
    isOutline = false,
    sectionData,
    requirementUid,
    requirementResponseUid,
    sectionUid,
    requirementResponseOrdinal,
    opportunityUid,
    requirementRemovalType = 'ARCHIVE'
  } = props;

  const [opened, setOpened] = useState(false);
  const dispatch = useDispatch();
  const updatedText = useSelector(selectUpdatedOutlineText);
  const outlineState = useSelector(selectOutline);

  const allSectionRoutes = sectionData.map(
    (section) => `/app/proposals/${proposalUid}/sections/${section.uid}/requirement-responses` as RouteWithGet
  );

  const topLevelSections = sectionData.filter(
    (section) => section.parentSectionUid === undefined || section.parentSectionUid === null
  );

  const undoMoveRequirementResponseMutation = useEnsisMutation('/app/requirement-responses', {
    requestType: 'patch',
    awaitRefetch: false,
    queryKeysToInvalidate: [
      '/app/requirement-responses' as RouteWithGet
    ].concat(allSectionRoutes),
    successMessage: 'Changes reverted'
  });

  const deleteRequirementMutation = useEnsisMutation(`/app/requirements/${requirementUid}/data`, {
    requestType: 'delete',
    contentType: 'application/json',
    successMessage: 'Requirement Successfully deleted!',
    onSuccess: () => {
      dispatch(deleteRequirement({ requirementUid, sectionUid }));
    },
    queryKeysToInvalidate: [`/app/opportunities/${opportunityUid}/requirements`, '/app/requirement-responses'],
    awaitRefetch: false
  });

  const handleUndoMoveRequirement = useCallback(() => {
    notifications.clean();
    const movingToArchived = sectionUid === ARCHIVED_SECTION_DATA.uid;
    undoMoveRequirementResponseMutation.mutate({
      edits: [{
        requirement_response_uid: requirementResponseUid,
        new_ordinal: requirementResponseOrdinal,
        new_section_uid: movingToArchived ? undefined : sectionUid,
        remove_from_section: movingToArchived
      }],
      proposal_uid: proposalUid ?? ''
    });
  }, [undoMoveRequirementResponseMutation]);

  const moveRequirementResponseMutation = useEnsisMutation('/app/requirement-responses', {
    requestType: 'patch',
    awaitRefetch: false,
    queryKeysToInvalidate: [
      '/app/requirement-responses' as RouteWithGet
    ].concat(allSectionRoutes),
    successMessage: (
    <Text fz='sm'> {'Requirement moved. '}
      <Anchor fz='sm' onClick={handleUndoMoveRequirement}>
      Undo
      </Anchor>
    </Text>),
    showFailureMessage: false,
    onError: (error) => {
      handlePotentialLockError(error, 'Unable to move requirement: A teammate is currently editing this section');
    }
  });

  const handleMoveRequirement = useCallback((newSectionUid: string) => {
    if (newSectionUid !== sectionUid) {
      moveRequirementResponseMutation.mutate({
        proposal_uid: proposalUid ?? '',
        edits: [{
          requirement_response_uid: requirementResponseUid,
          new_section_uid: newSectionUid,
          new_ordinal: 1,
          is_compliant: false,
          section_text_references: []
        }]
      });
    }
  }, [moveRequirementResponseMutation.mutate]);

  const archiveRequirementResponseMutation = useEnsisMutation('/app/requirement-responses', {
    requestType: 'patch',
    queryKeysToInvalidate: [
      '/app/requirement-responses' as RouteWithGet
    ].concat(allSectionRoutes),
    showSuccessMessage: !isOutline,
    awaitRefetch: false,
    successMessage: (
    <Text fz='sm'> {'Requirement archived. '}
      <Anchor fz='sm' onClick={handleUndoMoveRequirement}>
      Undo
      </Anchor>
    </Text>)
  });

  const handleOnClick = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    setOpened(false);
  }, []);

  const previousRequirements = useMemo(() => Object.values(outlineState.requirements).flat(1), [outlineState]);

  const handleArchiveRequirement = useCallback(() => {
    if (isOutline) {
      const fromIndex = outlineState.requirements[sectionUid]?.findIndex(
        (requirement) => requirement.requirementResponseUid === requirementResponseUid
      );
      dispatch(moveRequirement({
        fromSectionUid: sectionUid,
        toSectionUid: ARCHIVED_SECTION_DATA.uid,
        toIndex: 0,
        fromIndex
      }));
    };
    archiveRequirementResponseMutation.mutate({
      edits: [{ requirement_response_uid: requirementResponseUid, remove_from_section: true, is_compliant: false }],
      proposal_uid: proposalUid ?? ''
    }, {
      onError: (error: AxiosError) => {
        if (isOutline) {
          dispatch(updateOutlineRequirements({ requirements: previousRequirements }));
        }
        handlePotentialLockError(error, 'Unable to move requirement: A teammate is currently editing this section');
      }
    });
  }, [archiveRequirementResponseMutation]);

  const handelRemoveRequirement = useCallback(() => {
    if (requirementRemovalType === 'DELETE') {
      deleteRequirementMutation.mutate({});
    } else {
      handleArchiveRequirement();
    }
  }, [handleArchiveRequirement, deleteRequirementMutation]);
  const moveRequirementDropdownItem = (
      <Popover
        shadow="md"
        styles={{
          dropdown: {
            padding: '4px 0px',
            textAlign: 'start',
            justifyContent: 'start'
          }
        }}
        position='right-start'
        offset={0}
      >
      <Popover.Target>
        <Tooltip
          events={{
            hover: updatedText[requirementResponseUid] !== undefined,
            focus: false,
            touch: false
          }}
          label='Save before moving this requirement'
        >
          <Button
            variant="subtle"
            classNames={buttonClassNames}
            rightSection={<ArrowRight/>}
            leftSection={<FileUploadIcon/>}
            disabled={updatedText[requirementResponseUid] !== undefined || moveRequirementResponseMutation.isPending}
          >
            { 'Move Requirement...'}
          </Button>
         </Tooltip>
      </Popover.Target>
      <Popover.Dropdown style={{ borderRadius: '8px' }}>
          <MoveRequirementMenu
            topLevelSections={topLevelSections}
            onClick={handleMoveRequirement}
            sectionUidToDisable={sectionUid}
          />
      </Popover.Dropdown>
    </Popover>
  );

  return (
  <Popover
    opened={opened}
    classNames={{ dropdown: classes.popoverDropdown }}
    onChange={setOpened}
    position="bottom-start"
  >
    <Popover.Target>
      <ActionIcon
        onClick={(event) => { setOpened((o) => !o); event.stopPropagation(); }}
        c={isOutline ? 'var(--mantine-color-gray-5)' : 'var(--mantine-color-darkPurple-9)'}
        variant='subtle'
      >
        {isOutline ? <ThreeDotsVertical /> : <ThreeDotsHorizontal/>}
      </ActionIcon>
    </Popover.Target>
    <Popover.Dropdown>
      <Stack gap={0}>
        <Button
        classNames={buttonClassNames}
        leftSection={<Pen/>}
        onClick={(event) => { handleOpenEditRequirement(); handleOnClick(event); }}
        >
          Edit Requirement
        </Button>
        {!isOutline && moveRequirementDropdownItem}
        <Divider />
        <Tooltip
          events={{
            hover: updatedText[requirementResponseUid] !== undefined,
            focus: false,
            touch: false
          }}
          label='Save before archiving this requirement'
          >
            <Button
              onClick={(event) => { handelRemoveRequirement(); handleOnClick(event); }}
              classNames={{ ...buttonClassNames, root: classes.popoverMenuButtonItemRootRed }}
              leftSection={<Trash/>}
              disabled={updatedText[requirementResponseUid] !== undefined}
            >
              {`${requirementRemovalType === 'DELETE' ? 'Delete' : 'Archive'} Requirement`}
            </Button>
        </Tooltip>
      </Stack>
    </Popover.Dropdown>
  </Popover>
  );
}; ;
export default RequirementDropdown;
