import { Draggable, type DraggableProvided, Droppable, type DraggableRubric } from '@hello-pangea/dnd';
import { ActionIcon, Text, Box, Group, Divider } from '@mantine/core';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { ArrowRight } from '../../icons';
import SectionDropdown from '../DropdownMenus/SectionDropdown';
import { selectOutline } from '../../redux/store';
import { type SectionData } from '../../Pages/Editor/SectionEditor';
import { UserAssignmentDropdown } from '../DropdownMenus';
import classes from '../../Pages/Editor/Editor.module.css';
import { type RequirementType, type RequirementRemovalType } from '../../utils/requirementUtils';
import {
  getDraggableSubsectionIndex,
  getItemInfoFromDraggableId,
  undoDraggableSubsectionIndex
} from '../../utils/dndUtils';
import { RequirementRow } from '..';
import { ARCHIVED_SECTION_DATA } from '../../utils/stringUtils';

interface DroppableSectionProps {
  isArchive?: boolean
  sectionDragProvided?: DraggableProvided
  section: SectionData
  opportunityUid: string
  onRowClick: (requirementUid: string) => void
  focusedRequirementUid?: string
  onOpenRename: (sectionUid: string) => void
  onOpenDelete: (sectionUid: string) => void
  onOpenEditRequirement: (req: RequirementType, sectionUid: string) => void
  onOpenAddRequirement?: (sectionUid: string) => void
  onOpenAddSubsection: (sectionUid: string) => void
  sectionWidth: string
  archiveIsDroppable: boolean
  sectionsOpened: Set<string>
  handleSetSectionOpen: (sectionUid: string, isOpen?: boolean) => void
  showRequirements: boolean
  draggingRequirementResponseUid?: string
  checkedRequirements?: RequirementType[]
  onCheckRequirement?: (reqUid: RequirementType) => void
  showAssignedUsers: boolean
  requirementRemovalType: RequirementRemovalType
}

const DroppableSection: React.FC<DroppableSectionProps> = (props: DroppableSectionProps) => {
  const {
    onRowClick,
    focusedRequirementUid,
    sectionDragProvided,
    section,
    opportunityUid,
    isArchive = false,
    onOpenEditRequirement,
    handleSetSectionOpen,
    sectionWidth,
    sectionsOpened,
    archiveIsDroppable,
    showRequirements,
    draggingRequirementResponseUid,
    checkedRequirements,
    onCheckRequirement,
    requirementRemovalType,
    showAssignedUsers
  } = props;
  const inBulkEditMode = (checkedRequirements?.length ?? 0) > 0;
  const outlineState = useSelector(selectOutline);
  const isOpen = sectionsOpened.has(section.uid);
  const canOpenSection = showRequirements || section.parentSectionUid === undefined;
  const { sectionUid: currentSectionUid } = useParams();

  const draggableReq = (sectionUid: string, req: RequirementType) => {
    return (
      <RequirementRow
        opportunityUid={opportunityUid}
        inBulkEditMode={inBulkEditMode}
        requirementRemovalType={requirementRemovalType}
        requirement={req}
        tooltipWidth={`calc(${sectionWidth} - 80px)`}
        isChecked={checkedRequirements?.includes(req) ?? false}
        onCheckRequirement={() => {
          if (onCheckRequirement !== undefined) {
            onCheckRequirement(req);
          }
        }}
        onClickRequirement={() => { onRowClick(req.requirementUid); }}
        isFocusedRequirement={req.requirementUid === focusedRequirementUid}
        shouldShowRequirementDropdown={sectionUid !== ARCHIVED_SECTION_DATA.uid && !inBulkEditMode}
        handleOpenEditRequirement={() => { onOpenEditRequirement(req, sectionUid); }}
        isDragging={draggingRequirementResponseUid === req.requirementResponseUid}
      />
    );
  };

  const numberOfReqsInSection = showRequirements ? outlineState.requirements[section.uid]?.length : 0;
  const numberOfSubsectionsInSection = outlineState.subsections[section.uid]?.length ?? 0;
  const emptySectionText = isArchive
    ? 'No requirements are archived.'
    : (showRequirements
        ? 'No requirements in this section. Create a new one or move one here from another section.'
        : 'No subsections in this section. Create a new one or move one here from another section.'
      );

  const sectionRequirements = (
    <>
      {numberOfReqsInSection > 0
        ? (outlineState.requirements[section.uid]?.map((req, index) =>
          <div key={req.requirementResponseUid}>
            <Draggable
              key={req.requirementResponseUid}
              index={index}
              draggableId={`req_${req.requirementResponseUid}`}
              isDragDisabled={inBulkEditMode}
            >
              {(provided, snapshot) =>
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                >
                  {draggableReq(section.uid, req)}
                </div>
              }
            </Draggable>
            <Divider ml={20} color='var(--mantine-color-greyPurple-2)' />
          </div>))
        : <Text mih={12} />
      }
    </>);

  const getSectionContent = () => {
    if (!isOpen) {
      return <></>;
    }
    if (numberOfReqsInSection === 0 && numberOfSubsectionsInSection === 0) {
      return <Text ta='center' pl={50} pb={9} pt={10} pr={16} mih={32}> {emptySectionText} </Text>;
    } else {
      return <>
        {sectionRequirements}
        <>
          {outlineState.subsections[section.uid]?.map((subSection, index) => {
            // within <Droppable> indices must be sequential. adjusting req index to account for subsections
            const adjustedIndex = getDraggableSubsectionIndex(section.uid, index);
            return (

              <div style={{ paddingLeft: 20 }} key={subSection.uid} >
                <Draggable
                  isDragDisabled={inBulkEditMode}
                  draggableId={`subsection_${subSection.uid}`}
                  index={adjustedIndex}
                >
                  {(dragProvided, dragSnapshot) =>
                    <div
                      {...dragProvided.draggableProps}
                      ref={dragProvided.innerRef}
                    >
                      <DroppableSection
                        {...props}
                        section={subSection}
                        sectionDragProvided={dragProvided}
                        sectionWidth={`calc(${sectionWidth} - 40px)`}
                      />
                    </div>
                  }
                </Draggable>
              </div>);
          })
          }
        </>
      </>;
    }
  };
  const sectionTitleWidth = showAssignedUsers ? `calc(${sectionWidth} - 250px)` : `calc(${sectionWidth} - 100px)`;
  const sectionTitleGroup = (
    <Group
      onClick={() => {
        if (canOpenSection) {
          handleSetSectionOpen(section.uid);
        }
      }}
      {...sectionDragProvided?.dragHandleProps}
      justify="space-between"
      pl={8}
      pb={9}
      pt={12}
      mih={50}
    >
      <Group gap='xs' >
        {canOpenSection && (
          <ActionIcon
            className={isOpen ? classes.chevron : ''}
            variant='subtle'
            size='xs'
            c={isArchive ? 'var(--mantine-color-gray-6)' : 'var(--mantine-color-darkPurple-9)'}
            onClick={() => { handleSetSectionOpen(section.uid); }}
          >
            <ArrowRight />
          </ActionIcon>)
        }
        <Text
          fw={500}
          c={isArchive ? 'var(--mantine-color-gray-6)' : 'var(--mantine-color-darkPurple-9)'}
          ta='start'
          w={sectionTitleWidth}
        >
          {isArchive ? `${section.title} (${numberOfReqsInSection})` : `${section.title}`}
        </Text>
      </Group>
      <Group justify="flex-end">
        {(showAssignedUsers ?? false) &&
          <UserAssignmentDropdown
            sectionUid={section.uid}
            condensed={true}
          />
        }
        {!isArchive && !inBulkEditMode &&
          <SectionDropdown
            sections={outlineState.sections}
            sectionUid={section.uid}
            isCurrentPage={currentSectionUid === section.uid}
            isSubsection={section.parentSectionUid !== undefined}
            {...props}
          />
        }
      </Group>
    </Group>
  );

  const getDraggableClone = (provided: DraggableProvided, rubric: DraggableRubric) => {
    const draggableType = getItemInfoFromDraggableId(rubric.draggableId).type;
    return (
      <div
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        ref={provided.innerRef}
        style={{
          height: 32,
          width: 200,
          ...provided.draggableProps.style
        }}
      >
        {draggableType === 'req'
          ? draggableReq(section.uid, outlineState.requirements[section.uid][rubric.source.index])
          : (
            <Text
              fw={500}
              c={isArchive ? 'var(--mantine-color-gray-6)' : 'var(--mantine-color-darkPurple-9)'}
              ta='start'
              w={sectionWidth}
            >
              { /* eslint-disable-next-line max-len */
                outlineState.subsections[section.uid][undoDraggableSubsectionIndex(section.uid, rubric.source.index)]?.title
              }
            </Text>
            )}
      </div>
    );
  };

  return (
    <Droppable
      isDropDisabled={isArchive && !archiveIsDroppable}
      droppableId={`section_${section.uid}`}
      direction="vertical"
      renderClone={(provided, snapshot, rubric) => getDraggableClone(provided, rubric)}
    >
      {(sectionDropProvided, snapshot) =>
        <Box {...sectionDropProvided.droppableProps}
          style={{
            backgroundColor: (snapshot.isDraggingOver && !isOpen) ? 'var(--mantine-color-greyPurple-2)' : '',
            borderRadius: '4px',
            width: '100%',
            paddingRight: '12px'
          }}
          ref={sectionDropProvided.innerRef}>
          {sectionTitleGroup}
          <Divider color='var(--mantine-color-greyPurple-2)' />
          {getSectionContent()}
          {!isOpen ? <Box h={0}> {sectionDropProvided.placeholder} </ Box> : sectionDropProvided.placeholder}
        </Box>
      }
    </Droppable>
  );
};
export default DroppableSection;
