import { Text } from '@mantine/core';

import type {
  OpportunityFile,
  OpportunityRequirement,
  RequirementResponse,
  WithoutPermissions
} from '../types/apiTypes';

import { ARCHIVED_SECTION_DATA } from './stringUtils';

export type RequirementRemovalType = 'ARCHIVE' | 'DELETE';

export interface RequirementType {
  requirementResponseUid: string
  requirementIdentifier: string
  requirementText: string
  requirementUid: string
  sectionUid: string
  ordinal: number
  updatedAt: string
}

export const DEFAULT_EMPTY_REQUIREMENT: RequirementType = {
  requirementResponseUid: '',
  requirementIdentifier: '',
  requirementText: '',
  requirementUid: '',
  sectionUid: '',
  ordinal: ARCHIVED_SECTION_DATA.ordinal,
  updatedAt: ''
};

const getRequirementCounts = (
  opportunityFiles: OpportunityFile[],
  requirements: Array<WithoutPermissions<OpportunityRequirement>>
): Record<string, number> => {
  const requirementCounts: Record<string, number> = {};
  opportunityFiles.forEach((oppFile) => {
    const oppFileUid = oppFile?.uid ?? '';
    if (oppFileUid !== '') {
      requirementCounts[oppFileUid] = 0;
    }
  });
  requirements.forEach((req) => {
    const oppFileUid = req?.opportunity_file?.uid ?? '';
    if (oppFileUid !== '' && oppFileUid in requirementCounts) {
      requirementCounts[oppFileUid] += 1;
    }
  });
  return requirementCounts;
};

export const sortOpportunityFilesByUid = (opportunityFiles: OpportunityFile[]): OpportunityFile[] => (
  [...opportunityFiles].sort((a, b) => {
    const aUid = a.uid ?? '';
    const bUid = b.uid ?? '';
    if (aUid < bUid) {
      return -1;
    } else if (aUid > bUid) {
      return 1;
    }
    return 0;
  })
);

export const formatRequirementData: (
  data: RequirementResponse[]) => RequirementType[] = (data) => {
  return data.filter(
    (response) => (response.requirement !== undefined && response.requirement !== null)
  ).map((response) => {
    return {
      requirementResponseUid: response.uid,
      requirementText: response.requirement?.text ?? '',
      requirementUid: response.requirement?.uid ?? '',
      requirementIdentifier: response.requirement?.identifier ?? '',
      ordinal: response.ordinal ?? ARCHIVED_SECTION_DATA.ordinal,
      sectionUid: response.proposal_section?.uid ?? ARCHIVED_SECTION_DATA.uid,
      updatedAt: response.updated_at ?? ''
    };
  }).sort((a, b) => a.ordinal - b.ordinal);
};

export const sortOpportunityFilesByRequirementCount = (
  opportunityFiles: OpportunityFile[],
  requirements: Array<WithoutPermissions<OpportunityRequirement>>
): OpportunityFile[] => {
  if (requirements.length > 0) {
    const requirementCounts = getRequirementCounts(opportunityFiles, requirements);
    return [...opportunityFiles].sort(
      (fileA, fileB) => requirementCounts[fileB.uid ?? ''] - requirementCounts[fileA.uid ?? '']
    );
  }
  return sortOpportunityFilesByUid(opportunityFiles);
};

export const getRequirementIdentifierSpan = (requirementIdentifier: string) => (
  requirementIdentifier !== '' && (
    <Text span c='var(--mantine-color-dark-2)'>
      {`${requirementIdentifier} `}
    </Text>
  )
);
