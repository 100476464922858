import { Button, Modal, Popover, Stack } from '@mantine/core';

import { useCallback, useState } from 'react';

import { useDisclosure } from '@mantine/hooks';

import { useNavigate, useParams } from 'react-router-dom';

import ProposalStatusItem from './ProposalStatusItem';
import useEnsisQuery from '../../../hooks/useEnsisQuery';
import { ProposalStatusType } from '../../../utils/constants';
import CenteredLoader from '../../CenteredLoader';
import useEnsisMutation from '../../../hooks/useEnsisMutation';
import { hasPermissionsForObject } from '../../../utils/apiUtils';
import classes from '../DropdownMenus.module.css';
import { getProposalStatus } from '../../../utils/formattingUtils';
import { MoveProposalToAuthoring } from '../../Modals';
import { defaultModalProps } from '../../../utils/mantineUtils';
interface Props {
  proposalUid: string
  hovered: boolean
}

const SetupProposalStatusDropdown: React.FC<Props> = (props: Props) => {
  const { proposalUid, hovered } = props;
  const { data, isLoading } = useEnsisQuery(`/app/proposals/${proposalUid}/data`);
  const { organization } = useParams();
  const status = getProposalStatus(data);
  const [opened, setOpened] = useState(false);
  const hasEditPermission = hasPermissionsForObject(data, 'change');
  const [modalOpened, modalHandlers] = useDisclosure();
  const navigate = useNavigate();
  const canOpenDropdown = hasEditPermission;

  const updateProposalStatusMutation = useEnsisMutation(`/app/proposals/${proposalUid}/data`,
    {
      requestType: 'patch',
      successMessage: 'Proposal status updated',
      queryKeysToInvalidate: [`/app/proposals/${proposalUid}/data`]
    });

  const onClick = useCallback(() => {
    updateProposalStatusMutation.mutate({
      status: ProposalStatusType.AUTHORING
    }, {
      onSuccess: () => { navigate(`/org/${organization}/proposals/${proposalUid}`); }
    });
  }, [updateProposalStatusMutation]);

  const onClickAuthoring = useCallback(() => {
    setOpened(false);
    modalHandlers.open();
  }, []);
  if (isLoading) {
    return <CenteredLoader />;
  }

  return (
    <>
      <Modal opened={modalOpened} {...defaultModalProps}>
        <MoveProposalToAuthoring onContinue={onClick} close={modalHandlers.close} />
      </Modal>
      <Popover classNames={{ dropdown: classes.popoverDropdown }} opened={opened} onClose={() => { setOpened(false); }}>
        <Popover.Target>
          <Button
            classNames={{ root: hovered ? classes.buttonTargetRootHovered : classes.buttonTargetRoot }}
            variant='subtle'
            onClick={() => { if (canOpenDropdown) { setOpened((o) => !o); } }}>
            <ProposalStatusItem status={status} />
          </Button>
        </Popover.Target>
        <Popover.Dropdown>
          <Stack ta='start' gap={2}>
            <Button
              classNames={{ root: classes.buttonItemRoot, inner: classes.buttonItems }}
              variant='subtle'
            >
              <ProposalStatusItem
                showCheckmark={true}
                status={ProposalStatusType.SETUP}
              />
            </Button>
            <Button
              classNames={{ root: classes.buttonItemRoot, inner: classes.buttonItems }}
              variant='subtle'
              onClick={onClickAuthoring}
            >
              <ProposalStatusItem
                showCheckmark={false}
                status={ProposalStatusType.AUTHORING}
              />
            </Button>
          </Stack>
        </Popover.Dropdown>
      </Popover>
    </>
  );
};
export default SetupProposalStatusDropdown;
