import { Box, Radio, TextInput, Text } from '@mantine/core';

import { useCallback, useEffect, useMemo, useState } from 'react';

import { type DocumentLoadEvent } from '@react-pdf-viewer/core';

import WizardContent from './WizardContent';

import { CenteredLoader, OpportunityDocumentsViewer } from '../../components';
import { canRenderFile } from '../../utils/pdfUtils';
import useEnsisMutation from '../../hooks/useEnsisMutation';
import {
  type PageRangeType,
  getPageRangeDataFromOpportunityFile,
  getPageRangeError
} from '../../utils/pageRangeUtils';
import useEnsisQuery from '../../hooks/useEnsisQuery';
import { type RequirementResponse } from '../../types/apiTypes';
import { sortOpportunityFilesByUid } from '../../utils/requirementUtils';

interface Props {
  onDone: () => void
  proposalUid: string
}
const SelectRequirementPages: React.FC<Props> = (props: Props) => {
  const { onDone, proposalUid } = props;

  const { data: proposal } = useEnsisQuery(
    `/app/proposals/${proposalUid}/data`
  );
  const [currentFileIndex, setCurrentFileIndex] = useState(0);
  const [numPages, setNumPages] = useState(0);
  const [documentLoading, setDocumentLoading] = useState(false);
  const {
    data: opportunityFilesQuery,
    isLoading: opportunityFilesLoading
  } = useEnsisQuery(
    '/app/opportunity-files',
    {
      queryParams: { opportunity_uid: proposal?.opportunity?.uid ?? '', generate_download_url: true }
    }
  );

  const sortedOpportunityFiles = useMemo(() => (sortOpportunityFilesByUid(opportunityFilesQuery?.items ?? [])
  ), [opportunityFilesQuery]);

  const opportunityFile = sortedOpportunityFiles[currentFileIndex];
  const [pageRangesString, setPageRangesString] = useState('');
  const [pageRangeType, setPageRangeType] = useState<PageRangeType>('all-pages');

  const updatePageRangeMutation = useEnsisMutation(
    `/app/opportunity-files/${opportunityFile?.uid}/data`,
    {
      requestType: 'patch',
      showSuccessMessage: false,
      queryKeysToInvalidate: ['/app/opportunity-files']
    }
  );

  const handleOnInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageRangesString(event.currentTarget.value);
    setShouldCheckErrorString(true);
  };

  const handleDocumentStartLoading = useCallback(() => {
    (setDocumentLoading(true));
  }, []);

  const handleDocumentFinishedLoading = useCallback((e?: DocumentLoadEvent) => {
    setDocumentLoading(false);
    if (e !== undefined) {
      setNumPages(e.doc.numPages);
    }
  }, []);

  const handleClickNext = useCallback(async () => {
    const shouldExtractRequirements = pageRangeType !== 'none';
    const _pageRangesString = pageRangeType === 'other' ? pageRangesString : '';
    await updatePageRangeMutation.mutateAsync({
      requirements_page_ranges: _pageRangesString,
      should_extract_requirements: shouldExtractRequirements
    });
    if (currentFileIndex < sortedOpportunityFiles.length - 1) {
      setCurrentFileIndex(currentFileIndex + 1);
    } else {
      onDone();
    }
  }, [pageRangeType, pageRangesString, currentFileIndex, sortedOpportunityFiles, onDone]);

  const handleOnChange = useCallback((value: string) => {
    setPageRangeType(value as PageRangeType);
  }, []);

  const currentDocString = `Document ${currentFileIndex + 1} of ${sortedOpportunityFiles.length}:` +
    ` ${opportunityFile?.file_name}`;

  const handleOnClickPrevious = () => {
    if (currentFileIndex > 0) {
      setCurrentFileIndex(currentFileIndex - 1);
    }
  };

  const fileIsRenderable = useMemo(
    () => canRenderFile(opportunityFile?.file_name ?? ''),
    [opportunityFile]
  );
  const otherIsSelected = pageRangeType === 'other';
  const [shouldCheckErrorString, setShouldCheckErrorString] = useState(false);

  const resetState = useCallback(() => {
    if (opportunityFile !== undefined) {
      const stateData = getPageRangeDataFromOpportunityFile(opportunityFile);
      setPageRangesString(stateData[0]);
      setPageRangeType(stateData[1]);
    }
  }, [opportunityFile]);

  useEffect(resetState, [opportunityFile?.uid]);

  // don't show error on initial click of "specific pages" if no page range has been entered
  useEffect(() => {
    if (otherIsSelected && pageRangesString?.length > 0) {
      setShouldCheckErrorString(true);
    } else {
      setShouldCheckErrorString(false);
    }
  }, [pageRangesString, otherIsSelected]);

  const errorString = useMemo(() => {
    if (!shouldCheckErrorString) {
      return undefined;
    }
    return getPageRangeError(pageRangesString, numPages);
  }, [
    opportunityFile,
    shouldCheckErrorString,
    pageRangesString,
    numPages
  ]);

  const centeredLoader = <CenteredLoader h="calc(100vh - 300px)" />;

  if (currentFileIndex === undefined || opportunityFilesLoading || proposal === undefined) {
    return centeredLoader;
  }

  const nextButtonDisabled = errorString !== undefined || updatePageRangeMutation.isPending ||
    (otherIsSelected && pageRangesString.length === 0);

  const radioGroup = (
    <Radio.Group
      value={pageRangeType}
      onChange={handleOnChange}
    >
      <Radio value='all-pages' label='All pages' mt='md' />
      {fileIsRenderable && <Radio value='other' label='Specific pages' mt='md' />}
      {fileIsRenderable && <TextInput
        error={errorString}
        value={pageRangesString}
        onChange={handleOnInputChange}
        ml={28}
        mt={8}
        w={290}
        disabled={pageRangeType !== 'other'}
        placeholder='eg. 4-5, 8, 11-14'
      />}
      <Radio value='none' label='No requirements in this document' mt='md' />
    </Radio.Group>
  );
  return (
    <Box pt={24} display='flex' pr={0} mr={0}>
      <Box w='55vw' h='calc(100vh - 92px)'>
        <WizardContent
          titleCopy="Define Page Range"
          subtitleCopy="Select the pages that contain requirements."
          onClickNext={() => { void handleClickNext(); }}
          nextButtonDisabled={nextButtonDisabled}
          onClickPrevious={currentFileIndex > 0 ? handleOnClickPrevious : undefined}
        >
          {
            <Box ta='start'>
              <Text fw={600} truncate='end'>
                {currentDocString}
              </Text>
              {documentLoading ? centeredLoader : radioGroup}
            </Box>
          }
        </WizardContent>
      </Box>
      <Box w="45vw" h='calc(100vh - 200px)'>
        <OpportunityDocumentsViewer
          style={{ height: '100%' }}
          onStartDocumentLoad={handleDocumentStartLoading}
          onFinishDocumentLoad={handleDocumentFinishedLoading}
          currentFileIndex={currentFileIndex}
          onClickFile={(index: number) => { setCurrentFileIndex(index); }}
          requirementResponses={[] as RequirementResponse[]}
          proposal={proposal}
          focusedRequirementUid=''
        />
      </Box>
    </Box>
  );
};

export default SelectRequirementPages;
