import { Box, Checkbox, Group, Text, Tooltip } from '@mantine/core';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';

import { useHover } from '@mantine/hooks';

import { useSelector } from 'react-redux';

import {
  type RequirementRemovalType,
  type RequirementType,
  getRequirementIdentifierSpan
} from '../../utils/requirementUtils';
import { RequirementDropdown } from '../DropdownMenus';
import classes from './RequirementRow.module.css';

import { selectOutline } from '../../redux/store';

interface Props {
  requirement: RequirementType
  tooltipWidth: string
  handleOpenEditRequirement: () => void
  shouldShowRequirementDropdown: boolean
  isChecked: boolean
  isFocusedRequirement: boolean
  onCheckRequirement: () => void
  onClickRequirement: () => void
  requirementRemovalType: RequirementRemovalType
  inBulkEditMode: boolean
  opportunityUid: string
  isDragging?: boolean
}

export interface RequirementsRowRef {
  scrollIntoView: () => void
}

const RequirementRow = forwardRef<
RequirementsRowRef,
Props
>((props: Props, ref) => {
  const {
    requirement,
    onClickRequirement,
    isFocusedRequirement,
    isChecked,
    onCheckRequirement,
    shouldShowRequirementDropdown,
    handleOpenEditRequirement,
    tooltipWidth,
    requirementRemovalType,
    inBulkEditMode,
    opportunityUid,
    isDragging = false
  } = props;
  const [isOverflowed, setIsOverflow] = useState(false);
  const textElementRef = useRef<HTMLDivElement>(null);
  const { hovered, ref: hoverRef } = useHover();

  const backgroundColor = useMemo(() => {
    if (isDragging) {
      return undefined;
    }
    if (isFocusedRequirement) {
      return 'var(--mantine-color-green-1)';
    }
    if (isChecked) {
      return 'var(--mantine-color-lightPurple-1)';
    }
    if (hovered) {
      return 'var(--mantine-color-gray-1)';
    }
    return undefined;
  }, [isChecked, hovered, isFocusedRequirement]);

  const outlineState = useSelector(selectOutline);
  useImperativeHandle(ref, () => ({
    scrollIntoView: () => {
      hoverRef.current?.scrollIntoView();
    }
  }));

  useEffect(() => {
    if (textElementRef.current !== null) {
      setIsOverflow(textElementRef.current.scrollHeight > textElementRef.current.clientHeight);
    }
  }, [requirement.requirementText, tooltipWidth]);

  return (
    <Group className={classes.requirementRowGroup} ref={hoverRef} bg={backgroundColor}>
      <Box w={20} p={4}>
        {(hovered || inBulkEditMode) &&
          <Checkbox
            radius={4}
            value={requirement.requirementResponseUid}
            checked={isChecked}
            onChange={(event) => {
              event.stopPropagation();
              onCheckRequirement();
            }}
          />
        }
      </Box>
      <Tooltip
        events={{ hover: isOverflowed, touch: false, focus: false }}
        label={requirement.requirementText}
        w={tooltipWidth}
        multiline
        fz='md'
      >
        <Text
          onClick={onClickRequirement}
          ref={textElementRef}
          w={tooltipWidth}
          ta='start'
          style={{
            overflow: 'hidden',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
            textOverflow: 'ellipsis',
            cursor: 'pointer'
          }}
        >
          {getRequirementIdentifierSpan(requirement.requirementIdentifier ?? '')}
          {requirement.requirementText}
        </Text>
      </Tooltip>
      {shouldShowRequirementDropdown &&
        <RequirementDropdown
          requirementResponseUid={requirement.requirementResponseUid}
          requirementResponseOrdinal={requirement.ordinal}
          sectionData={outlineState.sections}
          isOutline={true}
          handleOpenEditRequirement={handleOpenEditRequirement}
          requirementUid={requirement.requirementUid}
          requirementRemovalType={requirementRemovalType}
          opportunityUid={opportunityUid}
          sectionUid={requirement.sectionUid}
        />}
    </Group>
  );
});

RequirementRow.displayName = 'RequirementRow';

export default RequirementRow;
